import React from "react";
import { Button, Col, Form, Modal, Row, Card, InputNumber } from "antd";


const CardInput = (props) => {
  const { title, children } = props;
  return (
    <Card style={{ marginTop: 12 }} title={title}>
      {children}
    </Card>
  );
};

const InputRow = (props) => {
  const { title, name } = props;
  return (
    <Form.Item name={name} label={title}>
      {/* <Input min={min} type="number" onKeyDown={(e) => onlyNumbers(e)} /> */}
      <InputNumber step={0.1} className="scoring-system-input" />
    </Form.Item>
  );
};

export default class ScoringModal extends React.PureComponent {
  handleSubmit = (values) => {
    const { onSubmit } = this.props;
    if (values) {
      onSubmit(values);
    }
  };

  renderOffence = () => {
    return (
      <CardInput title="OFFENSE">
        <InputRow name="passingTouchdowns" title="Passing TD" />
        <InputRow name="passingYards" title="Passing Yard" />
        <InputRow name="passingInterceptions" title="Offense INT" />
        <InputRow name="rushingTouchdowns" title="Rushing TD" />
        <InputRow name="receivingTouchdowns" title="Receiving TD" />
        <InputRow name="rushingYards" title="Rushing Yard" />
        <InputRow name="receivingYards" title="Receiving Yard" />
        <InputRow name="fumblesLost" title="Fumble lost" />
        <InputRow name="receptions" title="Reception" />
      </CardInput>
    );
  };

  renderDefence = () => {
    return (
      <CardInput title="DEFENSE">
        <InputRow name="defensiveTouchdowns" title="Defensive TD" />
        <InputRow name="sacks" title="Sack" />
        <InputRow name="interceptions" title="INT" />
        <InputRow name="fumblesRecovered" title="Fumble Recovery" />
        <InputRow name="Safety" title="Safety" />
      </CardInput>
    );
  };

  renderKickers = () => {
    return (
      <CardInput title="KICKERS">
        <InputRow name="kickingExtraPointsMade" title="Extra Point" />
        <InputRow name="kickingFieldGoalsMade" title="Field Goal" />
      </CardInput>
    );
  };

  renderLongTD = () => {
    return (
      <CardInput title="Long TD Bonuses">
        <InputRow name="receivingTouchdowns0to9" title="1-9 yds" />
        <InputRow name="receivingTouchdowns10to19" title="10-19 yds" />
        <InputRow name="receivingTouchdowns20to29" title="20-29 yds" />
        <InputRow name="receivingTouchdowns30to39" title="30-39 yds" />
        <InputRow name="receivingTouchdowns40to99" title="40+ yds" />
      </CardInput>
    );
  };

  renderOtherBonus = () => {
    return (
      <CardInput title="Other Bonuses">
        <InputRow name="rushingYardsBonus100" title="100 yd rushing game" />
        <InputRow name="passingYardsBonus300" title="300+ yd passing game" />
      </CardInput>
    );
  };

  renderPoints = () => {
    return (
      <CardInput title="Defense - Points Given Up">
        <InputRow name="pointsAllowed0" title="0" />
        <InputRow name="pointsAllowed1to6" title="1-6" />
        <InputRow name="pointsAllowed7to13" title="7-13" />
        <InputRow name="pointsAllowed14to20" title="14-20" />
        <InputRow name="pointsAllowed21to27" title="21-27" />
        <InputRow name="pointsAllowed28to34" title="28-34" />
        <InputRow name="pointsAllowed35to99" title="35+" />
      </CardInput>
    );
  };

  renderYardage = () => {
    return (
      <CardInput title="Defense - Yardage Given Up">
        <InputRow name="yardsAllowed0to199" title="0-199 yds" />
        <InputRow name="yardsAllowed200to299" title="200-299 yds" />
        <InputRow name="yardsAllowed300to349" title="300-349 yds" />
        <InputRow name="yardsAllowed350to399" title="350-399 yds" />
        <InputRow name="yardsAllowed400to449" title="400-449 yds" />
        <InputRow name="yardsAllowed450to999" title="400+ yds" />
      </CardInput>
    );
  };

  render() {
    const { onCancel } = this.props;

    return (
      <Modal
        width="800px"
        visible
        title="Scoring System"
        footer={null}
        onCancel={onCancel}
      >
        <Form
          labelCol={{ span: 16 }}
          wrapperCol={{ span: 8 }}
          initialValues={this.props.data}
          name="create-scoring"
          onFinish={this.handleSubmit}
          scrollToFirstError
        >
          {/* <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please enter your name!",
              },
            ]}
          >
            <Input />
          </Form.Item> */}
          <Row gutter={8}>
            <Col span={12}>
              {this.renderOffence()}
              {this.renderLongTD()}
              {this.renderPoints()}
            </Col>
            <Col span={12}>
              {this.renderDefence()}
              {this.renderKickers()}
              {this.renderOtherBonus()}
              {this.renderYardage()}
            </Col>
          </Row>
          <Row justify="end">
            <Col>
              <Button htmlType="submit" type="primary">
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}
