import React, { useEffect, useState } from "react";
import { Row, Col, Form, Select, Button } from "antd";
import "./style.css";
import Modal from "antd/lib/modal/Modal";
import { getDraftLoop, getHeadingData, getTeamNames } from "./helper";

const CustomDraft = ({
  isVisible,
  initialDraftType,
  rounds,
  players,
  draftOrder,
  onCreate,
  onCancel,
  teams,
}) => {
  const [order, setOrder] = useState(initialDraftType);
  const [headingData, setHeadingData] = useState("");
  const [teamNames, setTeamNames] = useState(getTeamNames(teams));
  const [values, setValues] = useState(
    (draftOrder?.length > 0 && draftOrder) || getDraftLoop(order, rounds, teams)
  );
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    setHeadingData(getHeadingData(rounds));
  }, [rounds]);

  useEffect(() => {
    setTeamNames(getTeamNames(teams));
  }, [teams, setTeamNames]);

  const handleOrderChange = (orderChange) => {
    setErrors([]);
    setOrder(orderChange);
    setValues(getDraftLoop(orderChange, rounds, teams));
  };

  //Reflecting custom draft order changes in values array
  const handleValueChange = (value, i, j) => {
    values[i][j] = value;
    setValues(values);
  };

  const onSave = () => {
    setErrors([]);
    let errors = [];
    let count;
    //Checking whether is there any team picking players more or less than the number of rounds
    for (let team_index = 0; team_index < players; team_index++) {
      count = 0;
      for (let j = 0; j < values.length; j++) {
        for (let k = 0; k < values[0].length; k++) {
          count += values[j][k] === team_index ? 1 : 0;
        }
      }
      if (count > rounds) {
        let error = ` ${teams[team_index].name} can't select players more than ${rounds} times.`;
        errors.push(error);
      } else if (count < rounds) {
        let error = ` ${teams[team_index].name} can't select players less than ${rounds} times.`;
        errors.push(error);
      }
    }
    setErrors(errors);

    if (errors.length === 0) {
      onCreate(values);
    }
  };

  return (
    <Modal
      className="custom-draft-popup-modal"
      title="Custom Draft"
      visible={isVisible}
      width=""
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={onSave}>
          Save Draft Order
        </Button>,
      ]}
    >
      <Row>
        <Col span={12}>
          <Form.Item name="custom-draft_type" label="Set Draft Type To">
            <Select
              text
              showSearch
              defaultValue={order}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(order) => handleOrderChange(order)}
            >
              <Select.Option value="Snake">Snake</Select.Option>
              <Select.Option value="Third Round Reversal">
                Third Round Reversal
              </Select.Option>
              <Select.Option value="NFL">NFL</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row className="custom-draft-popup-row">
        <Col span={24}>
          <div>
            <table>
              <thead>
                <tr>
                  <td></td>
                  {headingData &&
                    headingData.map((data, index) => (
                      <td key={index}>{` Round ${data + 1} `}</td>
                    ))}
                </tr>
              </thead>
              <tbody>
                {values &&
                  values[0].map((value, index) => {
                    let rowData = [];
                    for (let i = 0; i < rounds; i++) {
                      rowData.push(values[i][index]);
                    }
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        {rowData.map((data, i) => (
                          <td
                            key={Math.random()}
                            className="custom-draft-popup-table-td"
                          >
                            <Select
                              className="custom-draft-popup-table-teamSelect"
                              showSearch
                              defaultValue={teams[data].name}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              onChange={(value) =>
                                handleValueChange(value, i, index)
                              }
                            >
                              {teamNames.map((name, index) => (
                                <Select.Option
                                  key={Math.random()}
                                  value={index}
                                >
                                  {name}
                                </Select.Option>
                              ))}
                            </Select>
                          </td>
                        ))}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {errors.length > 0 &&
              errors.map((error) => (
                <div className="error-message">{error}</div>
              ))}
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

export default CustomDraft;
