import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import configureStore from "./redux/store/configure-store";
import { setAuthToken, isLoggedIn } from "./services/auth";
import NetworkService from "./redux/networkservice/networkService";
// import "antd/dist/antd.css";
import "./assets/scss/index.scss";
import * as serviceWorker from "./serviceWorker";

const store = configureStore();
if (isLoggedIn()) {
  setAuthToken();
}

NetworkService.setupInterceptors(store);
ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
