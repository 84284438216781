export const BASE_URL = process.env.REACT_APP_BASE_URL + "/";

export default {
  // Auth based routes
  LOGIN: `${BASE_URL}login`,

  // League based api
  GET_ALL_LEAGUES: `${BASE_URL}leagues`,
  CREATE_LEAGUE_WITH_TEAMS: `${BASE_URL}createLeagueWithTeams`,
  UPDATE_LEAGUE_WITH_TEAMS: `${BASE_URL}updateLeagueWithTeams`,
  DELETE_LEAGUE_WITH_ID: `${BASE_URL}deleteLeague`,

  // Scoring based api
  CREATE_SCORING: `${BASE_URL}createUpdateScoringSystem`,
  GET_ALL_SCORING_SYSTEM: `${BASE_URL}scoringSystems`,

  // Player based api
  GET_LEAGUE_AVALIABLE_PLAYERS: `${BASE_URL}leagueAvailablePlayers`,
  GET_ALL_AVALIABLE_PLAYERS: `${BASE_URL}players`,
  GET_ALL_DRAFTED_PLAYERS: `${BASE_URL}getDraftedPlayers`,

  // Team based api
  GET_ALL_TEAMS_BY_LEAGUE_ID: `${BASE_URL}teams`,
  GET_ALL_UNDO_LAST_PICK: `${BASE_URL}undoLastPick`,
  GET_ALL_MAKE_RECOMMEDATION: `${BASE_URL}makeRecommedation`,
  GET_CURRENT_LOOP_DETAILS: `${BASE_URL}getCurrentLoopDetails`,
  GET_SELECT_DRAFT_PLAYES: `${BASE_URL}selectDraftPlayer`,
  GET_SIMULATE_TO_END: `${BASE_URL}simulateToEnd`,
  DELETE_ALL_DRAFTED_PLAYES: `${BASE_URL}deleteAllDraftedPlayers`,
  DELETE_ALL_DRAFTED_PLAYES_WITH_KEEPERS: `${BASE_URL}deleteAllDraftedPlayersWithKeepers`,
  GET_ALL_PLAYER_BY_TEAM_ID: `${BASE_URL}teams`,
  GET_MOCK_TO_YOUR_NEXT_PICK: `${BASE_URL}mockToYourNextPick`,
  GET_ADVANCED_ANALYTICS: `${BASE_URL}advancedAnalytics`,

  // Keeper based api
  CREATE_KEEPER_LIST: `${BASE_URL}createKeeperList`,
  KEEPERS_BY_LEAGUE: `${BASE_URL}keepers`,
  CHECK_FOR_DRAFT_STARTS: `${BASE_URL}checkForDraftStarts`,
  UPDATE_KEEPER: `${BASE_URL}updateKeeperList`,

  // Algorithm based api
  SNAKE_DRAFT_LOOP: `${BASE_URL}snakeDraftLoops`,
  THIIRD_ROUND_REVERSAL_DRAFT_LOOP: `${BASE_URL}thirdRoundReversalDraftLoops`,
};
