/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from "react";
import { Modal, Table } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getAllDraftedPlayers } from "../../redux/actions/player-action";
import Spinner from "../spinner/spinner";

function AllDraftedPlayersModal(props) {
  const {
    onCancel,
    getAllDraftedPlayers: getAllDraftedPlayersFn,
    allDraftedPlayers,
  } = props;
  const { league, allDraftedPlayersLoading } = props;

  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [playerList, setPlayerList] = useState([]);

  useEffect(() => {
    const body = {
      league_id: league.league_id,
    };
    getAllDraftedPlayersFn(body);
  }, [getAllDraftedPlayersFn, league]);

  useEffect(() => {
    if (allDraftedPlayers?.status === "success") {
      setSuccess(true);
      setPlayerList(allDraftedPlayers.draftedPlayers);
    } else {
      setSuccess(false);
    }
    setLoading(allDraftedPlayersLoading);
  }, [allDraftedPlayers, allDraftedPlayersLoading]);

  const columns = [
    {
      title: "Pick",
      key: "pick",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Round",
      dataIndex: "round_id",
      key: "name",
    },
    {
      title: "Team",
      dataIndex: "userTeam",
    },
    {
      title: "Position",
      dataIndex: "fantasyPosition",
    },
    {
      title: "Players",
      dataIndex: "name",
    },
    {
      title: "Bye",
      dataIndex: "byeWeek",
    },
    {
      title: "ADP",
      dataIndex: "player_adp",
    },
    {
      title: "Points",
      dataIndex: "player_points",
    },
  ];

  return (
    <Modal
      width="800px"
      visible
      footer={null}
      onCancel={onCancel}
      title="All Drafted Players"
    >
      {loading ? (
        <Spinner />
      ) : success ? (
        playerList.length > 0 ? (
          <Table
            size={"small"}
            scroll={{ x: 500, y: 350 }}
            pagination={false}
            dataSource={playerList}
            columns={columns}
          />
        ) : (
          <h4>Nothing to display</h4>
        )
      ) : (
        <h4>Error!</h4>
      )}
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    allDraftedPlayers: state.Player.allDraftedPlayers,
    allDraftedPlayersLoading: state.Player.allDraftedPlayersLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAllDraftedPlayers,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllDraftedPlayersModal);
