export default {
  // Default values for scroing system
  SCORING_SYSTEM: {
    passingTouchdowns: "0",
    passingYards: "0",
    passingInterceptions: "0",
    rushingTouchdowns: "0",
    receivingTouchdowns: "0",
    rushingYards: "0",
    receivingYards: "0",
    fumblesLost: "0",
    receptions: "0",
    defensiveTouchdowns: "0",
    sacks: "0",
    interceptions: "0",
    fumblesRecovered: "0",
    Safety: "0",
    kickingExtraPointsMade: "0",
    kickingFieldGoalsMade: "0",
    receivingTouchdowns0to9: "0",
    receivingTouchdowns10to19: "0",
    receivingTouchdowns20to29: "0",
    receivingTouchdowns30to39: "0",
    receivingTouchdowns40to99: "0",
    rushingYardsBonus100: "0",
    passingYardsBonus300: "0",
    pointsAllowed0: "0",
    pointsAllowed1to6: "0",
    pointsAllowed7to13: "0",
    pointsAllowed14to20: "0",
    pointsAllowed21to27: "0",
    pointsAllowed28to34: "0",
    pointsAllowed35to99: "0",
    yardsAllowed0to199: "0",
    yardsAllowed200to299: "0",
    yardsAllowed300to349: "0",
    yardsAllowed350to399: "0",
    yardsAllowed400to449: "0",
    yardsAllowed450to999: "0",
  },
  NEGATIVE_SCROING_FIELDS: [
    "fumblesLost",
    "int",
    "points_given_up_28_34",
    "points_given_up_40",
    "yardage_given_up_400_yds",
  ],

  // Default values Draft setup screen
  SETUP_DRAFT: {
    no_of_teams: 12,
    no_of_players: 15,
    // user_draft_pos: 1,
    draft_type: "Snake",
    QB_min: 1,
    RB_min: 2,
    WR_min: 3,
    TE_min: 1,
    DE_min: 1,
    KI_min: 1,
    Flex_max: "0",
    flex_WR: false,
    flex_RB: false,
    flex_TE: false,
  },
};
