import URL from "../../config/routes";
import types from "./action-types";
import axiosCall from "./index";

export const getTeamsByLeague = (data) => {
  const path = URL.GET_ALL_TEAMS_BY_LEAGUE_ID;
  const responseType = types.GET_ALL_TEAMS_BY_LEAGUE_ID;
  return axiosCall("post", path, responseType, data);
};
export const getUndoLastPick = (data) => {
  const path = URL.GET_ALL_UNDO_LAST_PICK;
  const responseType = types.GET_ALL_UNDO_LAST_PICK;
  return axiosCall("post", path, responseType, data);
};
export const getmakeRecommedation = (data) => {
  const path = URL.GET_ALL_MAKE_RECOMMEDATION;
  const responseType = types.GET_ALL_MAKE_RECOMMEDATION;
  return axiosCall("post", path, responseType, data);
};
export const getCurrentLoopDetails = (data) => {
  const path = URL.GET_CURRENT_LOOP_DETAILS;
  const responseType = types.GET_CURRENT_LOOP_DETAILS;
  return axiosCall("post", path, responseType, data);
};
export const getselectDraftPlayer = (data) => {
  const path = URL.GET_SELECT_DRAFT_PLAYES;
  const responseType = types.GET_SELECT_DRAFT_PLAYES;
  return axiosCall("post", path, responseType, data);
};
export const getsimulateToEnd = (data) => {
  const path = URL.GET_SIMULATE_TO_END;
  const responseType = types.GET_SIMULATE_TO_END;
  return axiosCall("post", path, responseType, data);
};

export const getmockToYourNextPick = (data) => {
  const path = URL.GET_MOCK_TO_YOUR_NEXT_PICK;
  const responseType = types.GET_MOCK_TO_YOUR_NEXT_PICK;
  return axiosCall("post", path, responseType, data);
};

export const getadvancedAnalytics = (data) => {
  const path = URL.GET_ADVANCED_ANALYTICS;
  const responseType = types.GET_ADVANCED_ANALYTICS;
  return axiosCall("post", path, responseType, data);
};

export const deleteAllDraftedPlayes = (data) => {
  const path = URL.DELETE_ALL_DRAFTED_PLAYES;
  const responseType = types.DELETE_ALL_DRAFTED_PLAYES;
  return axiosCall("put", path, responseType, data);
};

export const deleteAllDraftedPlayesWithKeepers = (data) => {
  const path = URL.DELETE_ALL_DRAFTED_PLAYES_WITH_KEEPERS;
  const responseType = types.DELETE_ALL_DRAFTED_PLAYES;
  return axiosCall("put", path, responseType, data);
};

export const getPlayerByTeam = (data) => {
  const path = URL.GET_ALL_PLAYER_BY_TEAM_ID + "/" + data;
  const responseType = types.GET_ALL_PLAYER_BY_TEAM_ID;
  return axiosCall("get", path, responseType);
};