/* eslint-disable no-unused-expressions */
import { PlusOutlined,DeleteOutlined } from "@ant-design/icons";
import { Button, Col, Input, Modal, Row, Select, Typography } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  clearState,
  createKeeperList,
  getKeepersByLeague,
  updateKeeper,
  checkForDraftStarts,
} from "../../redux/actions/keeper.action";
import { getTeamsByLeague } from "../../redux/actions/teams.action";
import { getAllAvailablePlayers } from "../../redux/actions/player-action";
import Spinner from "../spinner/spinner";
const { Option } = Select;
const { TextArea } = Input;
const { Text } = Typography;

function KeeperModal(props) {
  const {
    onCancel,
    updateKeeper: updateKeeperFn,
    createKeeperList: createKeeperListFn,
    getTeamsByLeague: getTeamsByLeagueFn,
    getKeepersByLeague: getKeepersByLeagueFn,
    getAllAvailablePlayers: getAllAvailablePlayersFn,
    checkForDraftStarts: checkForDraftStartsFn,
    triggerApiCall,
  } = props;
  const {
    league,
    allPlayers,
    teamsByLeague,
    keeperByLeagueLoading,
    keeperByLeague,
    updateKeeperResponse,
    createKeeperResponse,
    clearState,
    checkForDraft,
  } = props;

  const [loading, setLoading] = useState(true);
  const [formError, setFormError] = useState(false);

  const [teamList, setTeamList] = useState([]);
  const [roundList, setRoundList] = useState([]);
  const [playerList, setPlayerList] = useState([]);
  const [disableStatus, setDisable] = useState(false);
  const [edit, setEdit] = useState(false);
  const [keeperList, setKeeperList] = useState([]);
  const [keeperPlayerIdList, setKeeperPlayerIdList] = useState([]);

  const draftOrderMap = useMemo(()=>{
    const result = {};
    const draftOrder = league.draft_order;
    const teams = league.teams;
    draftOrder.forEach((round, index)=>{
      if(!result[index+1])
        result[index+1] = {};
      round.forEach(teamIndex=>{
        const team = teams[teamIndex];
        if(!result[index+1][team.team_id])
          result[index+1][team.team_id] = 0;
          result[index+1][team.team_id]++;
      })

    })
    return result;
  },[league.draft_order, league.teams]);

  useEffect(() => {
    const body = {
      search: "",
      pagination: {
        pageNo: 1,
        limit: 1000,
      },
    };
    getAllAvailablePlayersFn(body);
  }, [getAllAvailablePlayersFn]);

  useEffect(() => {
    const body = {
      league_id: league.league_id,
      pagination: {
        pageNo: 1,
        limit: 100,
      },
    };
    getTeamsByLeagueFn(body);
    checkForDraftStartsFn(body);
    getKeepersByLeagueFn(body);
  }, [
    getKeepersByLeagueFn,
    getTeamsByLeagueFn,
    league.league_id,
    checkForDraftStartsFn,
  ]);

  useEffect(() => {
    allPlayers?.status === "success" &&
    league &&
    !keeperByLeagueLoading &&
    teamsByLeague?.status === "success"
      ? setLoading(false)
      : setLoading(true);
    return () => {
      setLoading(true);
    };
  }, [allPlayers, league, playerList, keeperByLeagueLoading, teamsByLeague]);

  useEffect(() => {
    return () => {
      clearState();
    };
  }, [clearState]);

  useEffect(() => {
    if (checkForDraft) {
      // setKeeperList(keeperByLeague.keepers);
      setDisable(checkForDraft.isDraftStarted);
    }
  }, [checkForDraft]);
  useEffect(() => {
    if (allPlayers) {
      let list = allPlayers.players.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      setPlayerList(list);
    }
  }, [allPlayers]);

  useEffect(() => {
    if (updateKeeperResponse?.status) {
      onCancel();
      if (updateKeeperResponse?.status === "success") {
        triggerApiCall();
      }
    }
  }, [onCancel, triggerApiCall, updateKeeperResponse]);

  useEffect(() => {
    if (createKeeperResponse?.status) {
      onCancel();
      if (createKeeperResponse?.status === "success") {
        triggerApiCall();
      }
    }
  }, [createKeeperResponse, onCancel, triggerApiCall]);

  useEffect(() => {
    const dateTs = new Date().getTime();
    if (
      !keeperByLeagueLoading &&
      keeperByLeague &&
      keeperByLeague.keepers.length > 0
    ) {
      keeperByLeague.keepers = keeperByLeague.keepers.map((keeper,index)=>({key:dateTs+index, ...keeper  }));

      setKeeperList(keeperByLeague.keepers);
      setEdit(true);
    } else if (!keeperByLeagueLoading && keeperByLeague)
      setKeeperList(keeperByLeague.keepers);
  }, [keeperByLeague, keeperByLeagueLoading]);




  useEffect(() => {
    league &&
      setRoundList([...Array(league.no_of_players).keys()].map((i) => i + 1));
  }, [league]);

  useEffect(() => {
    teamsByLeague && setTeamList(teamsByLeague.teams);
  }, [teamsByLeague]);

  useEffect(() => {
    setKeeperPlayerIdList(keeperList.map((value) => value.player_id));
  }, [keeperList]);

  return (
    <Modal
      width="800px"
      visible
      footer={null}
      onCancel={onCancel}
      title="Keepers"
    >
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Row
            gutter={16}
            justify="space-around"
            style={{ borderBottom: "1px solid #f0f0f0", marginBottom: 5 }}
          >
            <Col span={4}>
              <h4>TEAM</h4>
            </Col>
            <Col span={4}>
              <h4>ROUND</h4>
            </Col>
            <Col span={8}>
              <h4>PLAYER</h4>
            </Col>
            <Col span={8}>
              <h4>COMMENT</h4>
            </Col>
          </Row>
          {keeperList.map((item, key) => {
            if (!item.deleted)
              return (
                <Row
                key={item.key}
                  gutter={16}
                  justify="space-around"
                  style={{ marginBottom: 8 }}
                >
                  <Col span={4}>
                    <Select
                      disabled={disableStatus}
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Select Team"
                      defaultValue={item?.team_id}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(team_id) => {
                        let keepers = [...keeperList];
                        keepers[key].team_id = team_id;
                        setKeeperList(keepers);
                      }}
                    >
                      {teamList.map((team) => {
                        let disabled = false;
                        if (item.round_id) {
                          keeperList.forEach((i) => {
                            if (
                              i.round_id === item.round_id &&
                              i.team_id === team.team_id
                            )
                              disabled = true;
                          });
                        }

                        return (
                          <Option
                            key={team.team_id}
                            value={team.team_id}
                            disabled={disabled}
                          >
                            {team.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Col>
                  <Col span={4}>
                    <Select
                      disabled={disableStatus}
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Select Round"
                      defaultValue={item?.round_id}
                      filterOption={(input, option) =>
                        option.children
                          .toString()
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(round_id) => {
                        let keepers = [...keeperList];
                        keepers[key].round_id = round_id;
                        setKeeperList(keepers);
                      }}
                    >
                      {roundList.map((round_id, key) => {
                        let disabled = false;
                        if (item.team_id) {
                          keeperList.forEach((i) => {
                            if (
                              i.team_id === item.team_id &&
                              i.round_id === round_id
                            )
                              disabled = true;
                          });

                          if(!disabled){
                            if(!draftOrderMap[round_id][item.team_id])
                              disabled = true;
                          }
                        }


                        return (
                          <Option
                            key={key}
                            value={round_id}
                            disabled={disabled}
                          >
                            {round_id}
                          </Option>
                        );
                      })}
                    </Select>
                  </Col>
                  <Col span={8}>
                    <Select
                      disabled={disableStatus}
                      showSearch
                      style={{ width: "100%" }}
                      defaultValue={item?.player_id}
                      placeholder="Select Player"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(player_id) => {
                        let keepers = [...keeperList];
                        keepers[key].player_id = player_id;
                        setKeeperList(keepers);
                      }}
                    >
                      {playerList.map((player) => {
                        let disabled =
                          keeperPlayerIdList.indexOf(player.player_id) !== -1
                            ? true
                            : false;

                        return (
                          <Option
                            key={player.player_id}
                            value={player.player_id}
                            disabled={disabled}
                          >
                            {player.keyValue}
                          </Option>
                        );
                      })}
                    </Select>
                  </Col>
                  <Col span={7}>
                    <TextArea
                      disabled={disableStatus}
                      autoSize={true}
                      defaultValue={item?.comments}
                      onChange={(e) => {
                        let keepers = [...keeperList];
                        keepers[key].comments = e.target.value;
                        setKeeperList(keepers);
                      }}
                    />
                  </Col>
                  <Col span={1}>
                  <Button
                    type="primary"
                    icon={<DeleteOutlined />}
                    onClick={() =>{
                      let newKeeperList = keeperList.filter(list => list !== item  );
                      setKeeperList(newKeeperList);
                    }}
              >
              </Button>
                  </Col>
                </Row>
              );
          })}

          <Row justify="end">
            <Col>
              <Button
                type="primary"
                shape="round"
                icon={<PlusOutlined />}
                onClick={() =>
                  setKeeperList([
                    ...keeperList,
                    {
                      team_id: null,
                      round_id: null,
                      player_id: null,
                      comments: null,
                      key:new Date().getMilliseconds(),
                    },
                  ])
                }
                disabled={disableStatus}
              >
                Add
              </Button>
            </Col>
          </Row>

          <Row style={{ height: 30 }} />

          {formError && <Text type="danger">Options cannot be empty!</Text>}

          <Row justify="end">
            {keeperList.length > 0 && (
              <Button
                onClick={() => {
                  let keeperData = [...keeperList];
                  keeperData.forEach((mapData) => {
                    mapData.deleted = true;
                  });
                  updateKeeperFn({ keepersList: [], league_id:league.league_id  });
                }}
                type="primary"
                shape="round"
                className="mr-20"
                disabled={disableStatus}
              >
                Delete All & Save
              </Button>
            )}
            <Button
              type="primary"
              shape="round"
              onClick={() => {
                let error = keeperList.some(
                  (item) =>
                    (item.player_id == null ||
                      item.team_id == null ||
                      item.round_id == null));
                setFormError(error);
                if (!error) {
                  let keeperData = [];

                  keeperList.forEach((item) => {
                    if (
                      !(
                        item.player_id == null &&
                        item.team_id == null &&
                        item.round_id == null
                      )
                    )
                      keeperData.push(item);
                  });

                  edit
                    ? updateKeeperFn({ keepersList: keeperData, league_id:league.league_id })
                    : createKeeperListFn({ keepersList: keeperData, league_id:league.league_id });
                }
              }}
              disabled={disableStatus}
            >
              {edit ? "Update Keepers" : "Save Keepers"}
            </Button>
          </Row>
        </>
      )}
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    allPlayers: state.Player.allPlayers,
    teamsByLeague: state.Team.teamsByLeague,
    keeperByLeagueLoading: state.Keeper.keeperByLeagueLoading,
    keeperByLeague: state.Keeper.keeperByLeague,
    createKeeperResponse: state.Keeper.createKeeperResponse,
    updateKeeperResponse: state.Keeper.updateKeeperResponse,
    checkForDraft: state.Keeper.checkForDraft,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAllAvailablePlayers,
      getTeamsByLeague,
      createKeeperList,
      getKeepersByLeague,
      updateKeeper,
      clearState,
      checkForDraftStarts,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(KeeperModal);
