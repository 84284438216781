/* eslint-disable jsx-a11y/anchor-is-valid */
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import { Col, Dropdown, Menu, Modal, PageHeader, Row, Typography } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Logo from "../../../assets/img/logo1.png";
import { isLoggedIn } from "../../../services/auth";
import "./header.scss";

const { Title, Text } = Typography;

function Header(props) {
  const { history, location, title, currentLeague } = props;
  const [openModal, setOpenModal] = useState(false);
  const [user, setUser] = useState();

  useEffect(() => {
    async function getUser() {
      const user = await isLoggedIn();
      setUser(user);
    }
    getUser();
  }, []);

  const logout = () => {
    history.push("/login");
    localStorage.clear();
  };

  const UserDropDown = (
    <Menu className="top-menu">
      <Menu.Item key="logout" onClick={() => logout()}>
        Logout
      </Menu.Item>
      <Menu.Item key="support">
        <a
          href="https://support.fantasypoints.com/hc/en-us/requests/new?ticket_form_id=360000966851"
          target="_blank"
        >
          Feedback/Support
        </a>
      </Menu.Item>
    </Menu>
  );

  const PageHeaderLeft = (
    <Row align="middle">
      <Col>
        <img
          src={Logo}
          alt="Guru Fantasy Draft Tool"
          height="50px"
          onClick={() => {
            history.push("/");
          }}
        />
      </Col>
      <Col>
        <div style={{ marginLeft: 10, lineHeight: 1 }}>
          <Title
            level={3}
            className="heading-title"
            style={{
              fontVariant: "initial",
              fontFamily:
                "Kanit, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif",
              textTransform: "uppercase",
              fontSize: "18px",
              marginBottom: 0,
              // fontVariant: "small-caps",
              lineHeight: 1,
            }}
          >
            {title}
          </Title>
          <Text style={{ fontSize: 12, lineHeight: 0.8 }}>
            {currentLeague?.name}
          </Text>
        </div>
      </Col>
    </Row>
  );
  const onNavigation = (key, currentLeague) => {
    const { history } = props;
    if (currentLeague) {
      history.push(key, { league: currentLeague, edit: true });
    } else if (key !== "/" && history.location.pathname !== key) {
      setOpenModal(true);
    } else {
      history.push(key, { league: currentLeague, edit: true });
    }
  };
  const PageHeaderRight = (
    <Row>
      {location.pathname !== "/" && (
        <Menu
          onClick={({ key }) => {
            onNavigation(key, currentLeague);
          }}
          selectedKeys={[location.pathname]}
          mode="horizontal"
        >
          <Menu.Item key="/">Home</Menu.Item>
          <Menu.Item key="/setup-draft">Setup Draft</Menu.Item>
          <Menu.Item key="/draft">View Draft</Menu.Item>
          <Menu.Item key="/all-teams">All Teams</Menu.Item>
        </Menu>
      )}

      {user && (
        <Menu mode="horizontal">
          <Dropdown overlay={UserDropDown}>
            <Row align="middle">
              {/* <Avatar icon={} src={user.image_url} /> */}
              <UserOutlined className="mh-10" />
              <a className="ant-dropdown-link">
                {user.first_name} <DownOutlined />
              </a>
            </Row>
          </Dropdown>
        </Menu>
      )}
    </Row>
  );

  return (
    <Fragment>
      <Modal
        title="Alert"
        visible={openModal}
        onOk={() => setOpenModal(false)}
        onCancel={() => setOpenModal(false)}
      >
        <p>Please create a league and try again</p>
      </Modal>
      <PageHeader
        title={PageHeaderLeft}
        extra={PageHeaderRight}
        className="header"
      />
    </Fragment>
  );
}

export default withRouter(Header);
