import types from "../actions/action-types";

export default (state = [], action) => {
  switch (action.type) {
    case types.GET_ALL_LEAGUES:
    case `${types.GET_ALL_LEAGUES}_REJECTED`:
      return {
        ...state,
        leaguesList: action.updatePayload,
        deleteLeague: {}
      };

    case types.CREATE_LEAGUE_WITH_TEAMS:
    case `${types.CREATE_LEAGUE_WITH_TEAMS}_REJECTED`:
      return {
        ...state,
        createLeagueResponse: action.updatePayload,
      };
    case types.UPDATE_LEAGUE_WITH_TEAMS:
    case `${types.UPDATE_LEAGUE_WITH_TEAMS}_REJECTED`:
      return {
        ...state,
        updateLeagueResponse: action.updatePayload,
      };
    case types.DELETE_LEAGUE:
    case `${types.DELETE_LEAGUE}_REJECTED`:
      return {
        ...state,
        deleteLeague: action.updatePayload,
      };
    default:
      return state;
  }
};
