import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Form, Input, Button, Row, Col, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import app from "../../firebase/app";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";

import logo from "../../assets/img/logo2.png";
import { login } from "../../redux/actions/auth.action";
import "../../assets/scss/login.scss";

const getUserRoles = firebase.functions().httpsCallable("getUserRoles");

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { history } = this.props;
    const { loginResponse } = nextProps;
    if (loginResponse?.status === "success") {
      localStorage.setItem("token", loginResponse.token);
      localStorage.setItem("userDetails", JSON.stringify(loginResponse.user));
      history.push("/");
    }
    if (loginResponse?.status === "failed") {
      this.setState({
        // errorMessage: message.error(loginResponse?.message),
        errorMessage: message.error("Internal Error"),
      });
    }
  }

  handleSubmit = (values) => {
    app
      .auth()
      .signInWithEmailAndPassword(values.email, values.password)
      .then(async (data) => {
        getUserRoles().then((rolesData) => {
          const permitLogin = rolesData.data.some(
            (i) => i === "role_AhGMmvpQVrdmxIPqodij" || i === "role_OuhicOWKJHHvqVvkOGXA" || i === "role_I54ZyLBYFigJqJS59MuN"
          );
          if (permitLogin) {
            values.status = "success";
            values.uid = data.user.uid;
            values.firstName = data.user.displayName || "User";
            this.props.login(values);
          } else {
            this.setState({
              errorMessage: message.error(
                "Standard or Premium subscription is required"
              ),
            });
          }
        });
      })
      .catch((e) => {
        this.setState({
          errorMessage: message.error("Wrong username/password"),
        });
      });
  };

  render() {
    const { errorMessage } = this.state;
    return (
      <Fragment>
        {errorMessage}
        <Row className="full-height">
          <Col className="center-view" xs={24} sm={24} md={24}>
            <Form
              name="register"
              className="login-form"
              onFinish={this.handleSubmit}
              scrollToFirstError
            >
              <img width="100%" src={logo} alt="logo here" />
              <h4>Email</h4>
              <Form.Item
                name="email"
                hasFeedback
                validateStatus="primary"
                rules={[
                  {
                    type: "email",
                    message: "Invalid email id!",
                  },
                  {
                    required: true,
                    message: "Please enter your email id!",
                  },
                ]}
              >
                <Input prefix={<UserOutlined />} placeholder="Email" />
              </Form.Item>
              <h4>Password</h4>
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: "Please enter the password" },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>
              {/* <Form.Item name="remember" valuePropName="checked">
                <Checkbox>Keep me logged in</Checkbox>
              </Form.Item> */}
              <div className="center-view mt-20">
                <Button type="primary" htmlType="submit" shape="round">
                  Log in
                </Button>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
                className="mr-20 mt-10"
              >
                <Button
                  style={{ paddingLeft: 3, paddingRight: 3 }}
                  type="link"
                  href="https://www.fantasypoints.com/forgot-password"
                >
                  Forgot Password?
                </Button>
                <Button
                  style={{ paddingLeft: 3, paddingRight: 3 }}
                  type="link"
                  href="https://support.fantasypoints.com/hc/en-us/requests/new?ticket_form_id=360000966851"
                >
                  Feedback/Support
                </Button>
              </div>
              <div className="center-view mt-10">
                <span>Don't have an account?</span>
                <Button
                  style={{ paddingLeft: 3, paddingRight: 3 }}
                  type="link"
                  href="https://www.fantasypoints.com/subscribe"
                >
                  Sign Up
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginResponse: state.Auth.loginResponse,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ login }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
