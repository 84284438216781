import types from "../actions/action-types";

export default (state = [], action) => {
  switch (action.type) {
    case types.CREATE_SCORING:
    case `${types.CREATE_SCORING}_REJECTED`:
      return {
        ...state,
        createScoringResponse: action.updatePayload,
      };

    case types.GET_ALL_SCORING_SYSTEM:
    case `${types.GET_ALL_SCORING_SYSTEM}_REJECTED`:
      return {
        ...state,
        allScoringSystem: action.updatePayload,
      };
    default:
      return state;
  }
};
