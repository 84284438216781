import axiosCall from "./index";
import types from "./action-types";
import URL from "../../config/routes";

export const getAllAvailablePlayers = (data) => {
  const path = URL.GET_ALL_AVALIABLE_PLAYERS;
  const responseType = types.GET_ALL_AVALIABLE_PLAYERS;
  return axiosCall("post", path, responseType, data);
};

export const getLeagueAvailablePlayers = (data) => {
  const path = URL.GET_LEAGUE_AVALIABLE_PLAYERS;
  const responseType = types.GET_LEAGUE_AVALIABLE_PLAYERS;
  return axiosCall("post", path, responseType, data);
};

export const getAllDraftedPlayers = (data) => {
  const path = URL.GET_ALL_DRAFTED_PLAYERS;
  const responseType = types.GET_ALL_DRAFTED_PLAYERS;
  return axiosCall("post", path, responseType, data);
};
