import axiosCall from "./index";
import types from "./action-types";
import URL from "../../config/routes";

export const createScoring = (data) => {
  const path = URL.CREATE_SCORING;
  const responseType = types.CREATE_SCORING;
  return axiosCall("post", path, responseType, data);
};

export const getAllScoringSystem = (data) => {
  const path = URL.GET_ALL_SCORING_SYSTEM;
  const responseType = types.GET_ALL_SCORING_SYSTEM;
  return axiosCall("post", path, responseType, data);
};
