export function getDraftLoop(draftType, rounds, teams){
  let values = [];
  if (draftType === "Snake") {
    values = getSnakeDraftLoop(teams, rounds);
  } else if (draftType === "Third Round Reversal") {
    values = getThirdRoundReversalDraftLoop(teams, rounds);
  } else if (draftType === "NFL") {
    values = getNflDraftLoop(teams, rounds);
  }
  return values;
};

function getSnakeDraftLoop(teams, no_of_players){
  if (teams) {
    var draftLoop = [];

    for (var round = 1; round <= no_of_players; round++) {
      let row = [];
      for (var team_index = 0; team_index < teams.length; team_index++) {
        if (round % 2 !== 0) row.push(team_index);
        else row.push(teams.length - team_index - 1);
      }
      draftLoop.push(row);
    }
  }

  return draftLoop;
};

function getThirdRoundReversalDraftLoop(teams, no_of_players){
  if (teams) {
    var draftLoop = [];

    for (var round = 1; round <= no_of_players; round++) {
      let row = [];
      for (var team_index = 1; team_index <= teams.length; team_index++) {
        if (round == 1) {
          row.push(team_index - 1);
        } else if (round == 2) {
          row.push(teams.length - (team_index - 1) - 1);
        } else if (round == 3) {
          row.push(teams.length - (team_index - 1) - 1);
        } else {
          if ((round - 1) % 2 == 0) {
            row.push(teams.length - (team_index - 1) - 1);
          } else {
            row.push(team_index - 1);
          }
        }
      }
      draftLoop.push(row);
    }
  }

  return draftLoop;
};

function getNflDraftLoop(teams, no_of_players){
  if (teams) {
    var draftLoop = [];

    for (var round = 1; round <= no_of_players; round++) {
      let row = [];
      for (var team_index = 0; team_index < teams.length; team_index++) {
        row.push(team_index);
      }
      draftLoop.push(row);
    }
  }

  return draftLoop;
};

export function getHeadingData(rounds){
  let data = [];
  for (let round_index = 0; round_index < rounds; round_index++) {
    data.push(round_index);
  }
  return data;
};

export function getRowData(index, rounds, order, players){
  let values = getDraftLoop(order, rounds, players);
  let data = [];
  for (let i = 0; i < rounds; i++) {
    data.push(values[i][index]);
  }
  return data;
};

export function getTeamNames(teams){
  return teams.map((team) => team.name);
};
