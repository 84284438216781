import React from "react";
import { ArrowUpOutlined, AimOutlined } from "@ant-design/icons";
import WinnerIcon from "../assets/img/winner_icon.png";

export const IconsList = (icon) => {
  switch (icon) {
    case "up":
      return <ArrowUpOutlined style={{ color: "green" }} />;
    case "winner":
      return <img src={WinnerIcon} height="20px" alt="Winner" />;
    case "aim":
      return <AimOutlined style={{ color: "blue" }} />;
    default:
      break;
  }
};
