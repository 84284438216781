/* eslint-disable no-unused-expressions */
import React, { Component } from "react";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  InputNumber,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "../../assets/scss/login.scss";
import initial from "../../constants/initialData";
import ScoringModal from "../../elements/modals/scoringModal";
import Spinner from "../../elements/spinner/spinner";
import { onlyNumbers } from "../../helpers/helpers";
import { createLeague, updateLeague } from "../../redux/actions/leagues.action";
import {
  createScoring,
  getAllScoringSystem,
} from "../../redux/actions/scoring.action";
import { deleteAllDraftedPlayes } from "../../redux/actions/teams.action";
import { isLoggedIn } from "../../services/auth";
import Layout from "../common/layout";
import CustomDraft from "./CustomDraft";
const { Option } = Select;
const { confirm } = Modal;

class SetupDraft extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      searchPlayer: "",
      scoringModal: false,
      numberOfTeam: 12,
      numberOfPlayers: 15,
      visible: false,
      selectTeam: 1,
      loading: true,
      scoringOptions: [],
      customScoring: null,
      customScoringId: null,
      teams: [],
      currentLeague: props.location.state?.league || null,
      edit: props.location.state?.edit || false,
      draftType: props.location.state?.league.draft_type || "Snake",
      createScoringdData: {},
      disableFlex: true,
      showPopup: false,
      values: [],
      draftOrder: props.location.state?.league.draft_order || [],
    };
    this.form = null;
  }



  async componentDidMount() {
    const { currentLeague } = this.state;
    const user = await isLoggedIn();
    if (currentLeague) {
      let indexs = 0;
      currentLeague.teams.forEach((data, index) => {
        if (data.is_your_team === 1) {
          indexs = index + 1;
        }
      });
      this.setState({
        teams: currentLeague.teams,
        selectTeam: indexs,
        numberOfTeam: currentLeague.teams.length,
        numberOfPlayers: currentLeague.no_of_players,
      });

      !currentLeague.Flex_max || currentLeague.Flex_max === 0
        ? this.setState({ disableFlex: true })
        : this.setState({ disableFlex: false });
    }
    this.setState({ user }, () => {
      this.triggergetAllScoringSystem();
    });
  }

  componentDidUpdate(prevProps) {
    const { currentLeague, createScoringdData } = this.state;
    const {
      createLeagueResponse,
      updateLeagueResponse,
      history,
      deleteAllDraftedPlayers,
      createScoringResponse,
    } = this.props;
    if (prevProps.createLeagueResponse !== createLeagueResponse) {
      if (createLeagueResponse?.status === "success") {
        this.form?.resetFields();
        this.setState({ numberOfTeam: 12 });
        history.push("draft", { league: createLeagueResponse?.league });
      }
      if (createLeagueResponse?.status === "failed") {
        message.error(createLeagueResponse?.message);
      }
    }
    if (prevProps.deleteAllDraftedPlayers !== deleteAllDraftedPlayers) {
      if (deleteAllDraftedPlayers?.status === "success") {
        message.success(" All Draft Players Delected Successfully");
      }
    }
    if (prevProps.updateLeagueResponse !== updateLeagueResponse) {
      if (updateLeagueResponse?.status === "success") {
        message.success("League Updated Successfully");
        history.push("draft", { league: updateLeagueResponse?.league });
        this.setState({
          currentLeague: updateLeagueResponse.league,
          teams: updateLeagueResponse.league.teams,
          selectTeam: currentLeague.user_draft_pos,
        });
      } else {
        message.error(updateLeagueResponse?.message);
      }
    }
    if (prevProps.createScoringResponse !== createScoringResponse) {
      if (
        createScoringResponse?.status === "success" &&
        JSON.stringify(createScoringResponse) !==
          JSON.stringify(createScoringdData)
      ) {
        this.triggergetAllScoringSystem();
        this.setState({ createScoringdData: createScoringResponse });
      }
      if (createScoringResponse?.status === "failed") {
        message.error(createScoringResponse?.message);
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextprops) {
    const { allScoringSystem } = nextprops;
    let newData = [];
    if (allScoringSystem?.status === "success") {
      if (allScoringSystem.scoringSystems.length > 0) {
        let noCustom = true;
        allScoringSystem.scoringSystems.forEach((data) => {
          if (!data.isCustom) {
            newData.push(
              <Option
                value={data.scoring_system_id}
                key={data.scoring_system_id}
              >
                {data.name}
              </Option>
            );
          } else {
            noCustom = false;
            this.setState({
              customScoringId: data.scoring_system_id,
              customScoringData: data,
              customScoring: data,
            });
            newData.push(
              <Option
                value={data.scoring_system_id}
                key={data.scoring_system_id}
              >
                Custom
              </Option>
            );
          }
        });
        if (noCustom) {
          this.setState({ noCustom: true });
          newData.push(
            <Option value={"custom"} key={"custom"}>
              Custom
            </Option>
          );
        }
      }
      this.setState({ loading: false, scoringOptions: newData });
    }
  }

  triggergetAllScoringSystem = async () => {
    const { getAllScoringSystem } = this.props;
    const { user } = this.state;
    const pagination = {
      pageNo: 1,
      limit: 100,
    };
    const body = {
      uid: user.uid,
      pagination,
      league_id: this.state.currentLeague?.league_id || 0,
    };
    getAllScoringSystem(body);
  };

  handleChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  onScoringSelect = (selection) => {
    if (selection === this.state.customScoringId || selection === "custom") {
      this.setState({ scoringModal: true });
    }
  };

  handleTeamChange = (event, index, me, data) => {
    let { teams, user, edit } = this.state;
    const team = {
      name: event.target.value,
      is_your_team: edit ? data.is_your_team : me,
      point_starters: data?.point_starters || "0.0",
      rank_starters: data?.rank_starters || "0.0",
      total_points: data?.total_points || "0.0",
      total_ranks: data?.total_ranks || "0.0",
      uid: data?.uid || user.uid,
      team_id: data.team_id,
    };
    if (edit) {
      team.team_id = data.team_id;
    }
    teams[index] = team;

    this.setState({ teams });
  };

  onScoringSubmit = (values) => {
    // const { createScoring } = this.props;
    // this.setState({ scoringModal: false }, async () => {
    //   const user = await isLoggedIn();
    //   values.uid = user.uid;
    //   values.league_id = this.state.currentLeague?.league_id || 0;
    //   createScoring(values);
    // });
  };

  handleSubmit = (values) => {
    const { teams, user, edit, currentLeague } = this.state;
    const { createLeague, updateLeague } = this.props;
    if (values) {
      let entriesCount =
        parseInt(values.DE_min) +
        parseInt(values.Flex_max) +
        parseInt(values.KI_min) +
        parseInt(values.QB_min) +
        parseInt(values.RB_min) +
        parseInt(values.TE_min) +
        parseInt(values.WR_min);
      let flexValid = true;
      if (
        values.Flex_max > 0 &&
        !values.flex_RB &&
        !values.flex_TE &&
        !values.flex_WR
      ) {
        flexValid = false;
      }
      if (teams.length === 0) {
        message.error("Team List should not be empty");
      } else {
        let teamNames = teams.map(function (obj) {
          return obj.name;
        });
        const hasDuplicates = teamNames.some(
          (item, i) => teamNames.indexOf(item) !== i
        );
        if (hasDuplicates) {
          message.error("Duplicate Team Names found");
        } else if (entriesCount > parseInt(this.state.numberOfPlayers)) {
          message.error("Starter By Position exceeds Total Players Drafted");
        } else if (!flexValid) {
          message.error("Check atleast one flex position");
        } else {
          const newTeams = teams.map((obj, key) => {
            if (this.state.selectTeam === key + 1)
              return { ...obj, is_your_team: true };
            else return { ...obj, is_your_team: false };
          });

          values.uid = user.uid;
          values.teams = newTeams;
          values.no_of_teams = this.state.numberOfTeam;
          values.no_of_players = this.state.numberOfPlayers;
          values.user_draft_pos = this.state.selectTeam;

          if (this.state.customScoringId)
            if (values.scoring_system_id === this.state.customScoringId) {
              // select and update custom scoring with new data
              values.scoringSystem = this.state.customScoring;
            } else {
              // keep previous custom scoring while updating league
              values.scoringSystem = this.state.customScoringData;
            }
          else {
            if (values.scoring_system_id === "custom") {
              // select and create custom scoring
              values.scoring_system_id = null;
              values.scoringSystem = this.state.customScoring;
            } else {
              // set scoringSystem to {} on create league with existing scoring system
              values.scoringSystem = {};
            }
          }

          values.draft_order = this.state.draftOrder;

          if (edit) {
            values.league_id = currentLeague.league_id;
            confirm({
              title: "Update League",
              icon: <ExclamationCircleOutlined />,
              okText: "Yes",
              cancelText: "No",
              content: (
                <>
                  <p>
                    This will reset your drafted players.
                    <br />
                    Are you sure you want to update?
                  </p>
                </>
              ),
              onOk() {
                updateLeague(values);
              },
              onCancel() {},
            });
          } else {
            createLeague(values);
          }
        }
      }
    }
  };

  // onReset = () => {
  //   this.setState({ numberOfTeam: [] }, () => {
  //     this.form?.resetFields();
  //   });
  //   const { currentLeague } = this.state;
  //   if (currentLeague) {
  //     const { deleteAllDraftedPlayes } = this.props;
  //     const body = {
  //       league_id: currentLeague.league_id,
  //     };
  //     deleteAllDraftedPlayes(body);
  //   }
  // };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  onNavigation = (page, currentLeague) => {
    const { history } = this.props;
    if (currentLeague) {
      history.push(page, { league: currentLeague });
    } else {
      this.showModal();
    }
  };

  renderLeftView = () => {
    const {
      numberOfTeam,
      scoringOptions,
      edit,
      draftType,
    } = this.state;


    return (
      <>
        <Card
          title="Basic Details"
          bordered={false}
          headStyle={{
            backgroundColor: "#c33",
            color: "#fff",
          }}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please enter the name of the league",
                  },
                ]}
              >
                <Input
                  disabled={edit}
                  onChange={(e) =>
                    this.setState({ searchPlayer: e.target.value })
                  }
                  placeholder="League Name Here..."
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                validateTrigger="onBlur"
                name="no_of_teams"
                label="Number of Teams"
                initialValue={numberOfTeam}
              >
                <InputNumber
                  disabled={edit}
                  onKeyDown={(e) => onlyNumbers(e)}
                  min={3}
                  max={100}
                  onChange={(number) => {
                    this.setState({ numberOfTeam: number });
                    let newTeams = this.state.teams.slice(0, number);
                    this.setState({ teams: newTeams });
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="no_of_players"
                label="Total Players Drafted"
                initialValue={this.state.numberOfPlayers}
              >
                <InputNumber
                  disabled={edit}
                  onBlur={() => true}
                  min={9}
                  max={100}
                  onKeyDown={(e) => onlyNumbers(e)}
                  onChange={(number) =>
                    this.setState({ numberOfPlayers: number })
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="user_draft_pos"
                label="Your Draft Position"
                rules={[
                  {
                    required: true,
                    message: "Please enter your position",
                  },
                ]}
                // initialValue={selectTeam}
              >
                <InputNumber
                  min={1}
                  max={numberOfTeam}
                  onKeyDown={(e) => onlyNumbers(e)}
                  onChange={(number) => this.setState({ selectTeam: number })}
                />
              </Form.Item>
            </Col>
            <Col span={draftType === "Custom" ? 8 : 12}>
              <Form.Item name="draft_type" label="Draft Type">
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(draftType) =>
                    this.setState({ draftType: draftType })
                  }
                >
                  <Option value="Snake">Snake</Option>
                  <Option value="Third Round Reversal">
                    Third Round Reversal
                  </Option>
                  <Option value="NFL">NFL</Option>
                  <Option value="Custom">Custom</Option>
                </Select>
              </Form.Item>
            </Col>
            {draftType === "Custom" && (
              <Col span={4}>
                <Form.Item name="draft_type_button" label=" ">
                  <Button
                    style={{
                      backgroundColor: "#c33",
                      color: "#fff",
                    }}
                    onClick={() => this.setState({ showPopup: true })}
                  >
                    Set
                  </Button>
                </Form.Item>
              </Col>
            )}
          </Row>
        </Card>

        <Card
          title="Starter By Position"
          bordered={false}
          headStyle={{
            backgroundColor: "#c33",
            color: "#fff",
          }}
        >
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item name="QB_min" label="QB">
                <InputNumber
                  min={0}
                  max={100}
                  onKeyDown={(e) => onlyNumbers(e)}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="RB_min" label="RB">
                <InputNumber
                  min={0}
                  max={100}
                  defaultValue={2}
                  onKeyDown={(e) => onlyNumbers(e)}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="WR_min" label="WR">
                <InputNumber
                  min={0}
                  max={100}
                  defaultValue={3}
                  onKeyDown={(e) => onlyNumbers(e)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item name="TE_min" label="TE">
                <InputNumber
                  min={0}
                  max={100}
                  defaultValue={1}
                  onKeyDown={(e) => onlyNumbers(e)}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="DE_min" label="DST">
                <InputNumber
                  min={0}
                  max={100}
                  defaultValue={1}
                  onKeyDown={(e) => onlyNumbers(e)}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="KI_min" label="K">
                <InputNumber
                  min={0}
                  max={100}
                  defaultValue={1}
                  onKeyDown={(e) => onlyNumbers(e)}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col
              span={24}
              // style={{ border: "1px solid blue" }}
              className="blue-card"
            >
              <Form.Item label="Flex">
                <Form.Item
                  name="Flex_max"
                  style={{
                    display: "inline-block",
                    minWidth: "60px",
                    marginRight: 16,
                  }}
                >
                  <InputNumber
                    min={0}
                    max={3}
                    onKeyDown={(e) => onlyNumbers(e)}
                    onChange={(v) => {
                      !v || v === 0
                        ? this.setState({ disableFlex: true })
                        : this.setState({ disableFlex: false });
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="flex_WR"
                  valuePropName="checked"
                  style={{ display: "inline-block" }}
                  required
                >
                  <Checkbox disabled={this.state.disableFlex}>WR</Checkbox>
                </Form.Item>
                <Form.Item
                  name="flex_RB"
                  valuePropName="checked"
                  style={{ display: "inline-block" }}
                >
                  <Checkbox disabled={this.state.disableFlex}>RB</Checkbox>
                </Form.Item>
                <Form.Item
                  name="flex_TE"
                  valuePropName="checked"
                  style={{ display: "inline-block" }}
                >
                  <Checkbox disabled={this.state.disableFlex}>TE</Checkbox>
                </Form.Item>
              </Form.Item>
            </Col>
          </Row>
        </Card>

        <Card
          title="Scoring System"
          bordered={false}
          headStyle={{
            backgroundColor: "#c33",
            color: "#fff",
          }}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Select Scoring System"
                name="scoring_system_id"
                rules={[
                  {
                    required: true,
                    message: "Please select the scoring system",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select"
                  optionFilterProp="children"
                  // onChange={(text) => this.onScoringSelect(text)}
                  onSelect={(text) => this.onScoringSelect(text)}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {scoringOptions}
                  {/* <Option value="custom">Custom</Option> */}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </>
    );
  };

  renderRightView = () => {
    const { numberOfTeam, selectTeam, teams, edit } = this.state;
    const count = Array.apply(null, { length: numberOfTeam }).map(
      Number.call,
      Number
    );
    let array = edit ? teams : count;

    return (
      <>
        <Card
          title="Enter Team Names"
          bordered={false}
          headStyle={{
            backgroundColor: "#c33",
            color: "#fff",
          }}
        >
          {array.map((data, index) => {
            let me = index === selectTeam - 1;

            // if (!(teams[index] && teams[index].name)) {
            //   teams[index].name = teams[index]?.name || `Team #${index + 1}`;
            //   this.setState({ teams });
            // }

            const team = {
              name: data.name || `Team #${index + 1}`,
              // is_your_team: data.is_your_team || me,
              point_starters: data?.point_starters || "0.0",
              rank_starters: data?.rank_starters || "0.0",
              total_points: data?.total_points || "0.0",
              total_ranks: data?.total_ranks || "0.0",
              uid: data?.uid || this.state.user.uid,
              team_id: data.team_id,
            };

            if (teams.length < index + 1) {
              let newTeams = teams;
              newTeams.push(team);
              this.setState({ teams: newTeams });
            }

            return (
              <Row
                gutter={16}
                className={me ? "blue-card" : ""}
                style={{
                  alignItems: "center",
                  paddingLeft: 0,
                  paddingRight: 0,
                  marginBottom: me ? 8 : 0,
                }}
              >
                <Col span={4}>{me ? "Your Team" : `Team #${index + 1}`}</Col>
                <Col span={20}>
                  <Form.Item
                    name={`teams_${index + 1}`}
                    value={teams[index]}
                    initialValue={data?.name || `Team #${index + 1}`}
                    onChange={(e) => this.handleTeamChange(e, index, me, data)}
                    // label={`Team #${index + 1} ${me ? " - YOU" : ""}`}
                    rules={[
                      {
                        required: true,
                        message: "Team name cannot be empty!",
                      },
                    ]}
                  >
                    <Input
                      // defaultValue={data?.name || `Team #${index + 1}`}
                      placeholder="Enter Team Name"
                    />
                  </Form.Item>
                </Col>
              </Row>
            );
          })}
        </Card>
      </>
    );
  };

  render() {
    const {
      scoringModal,
      loading,
      currentLeague,
      visible,
      showPopup,
      teams,
      numberOfPlayers,
      numberOfTeam,
      draftOrder,
      edit,
    } = this.state;

    const onSaveCustomDraft = (values) => {
      this.setState({
        showPopup: false,
        draftOrder: values,
      });
    };

    const onCancel = () => {
      this.setState({ showPopup: false });
    };

    return (
      <Layout title="Setup Draft" currentLeague={currentLeague}>
        <Form
          layout={"vertical"}
          validateTrigger="onBlur"
          ref={(ref) => (this.form = ref)}
          name="create-league"
          onFinish={this.handleSubmit}
          // onReset={this.onReset}
          scrollToFirstError
          initialValues={currentLeague || initial.SETUP_DRAFT}
        >
          {loading ? (
            <Spinner />
          ) : (
            <>
              <Row gutter={16}>
                <Col lg={12} md={12} sm={24} xs={24}>
                  {this.renderLeftView()}
                </Col>
                <Col lg={12} md={12} sm={24} xs={24}>
                  {this.renderRightView()}
                </Col>
              </Row>

              <Row justify="center" style={{ marginTop: 12 }}>
                <Col>
                  <Button
                    htmlType="submit"
                    className="mr-20"
                    type="primary"
                    shape="round"
                  >
                    {this.state.edit ? "Update League" : "Save League"}
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </Form>

        {showPopup && (
          <CustomDraft
            isVisible={showPopup}
            teams={teams}
            rounds={numberOfPlayers}
            initialDraftType={"Snake"}
            players={numberOfTeam}
            draftOrder={edit ? currentLeague.draft_order : draftOrder}
            onCreate={onSaveCustomDraft}
            onCancel={onCancel}
          />
        )}

        {scoringModal && (
          <ScoringModal
            onCancel={() => this.setState({ scoringModal: false })}
            onSubmit={(values) => {
              this.setState({ customScoring: values });
              this.setState({ scoringModal: false });
            }}
            data={this.state.customScoring || initial.SCORING_SYSTEM}
          />
        )}
        <Modal
          title="Alert"
          visible={visible}
          onOk={() => this.setState({ visible: false })}
          onCancel={() => this.setState({ visible: false })}
        >
          <p>Please create a league and try again</p>
        </Modal>
        <Modal
          title="Update League"
          visible={this.state.updateLeagueVisible}
          onOk={() => this.setState({ updateLeagueVisible: false })}
          onCancel={() => this.setState({ updateLeagueVisible: false })}
        >
          <p>This will reset your drafted players.</p>
          <p>Are you sure you want to update?</p>
        </Modal>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    createScoringResponse: state.Score.createScoringResponse,
    allScoringSystem: state.Score.allScoringSystem,
    createLeagueResponse: state.Leagues.createLeagueResponse,
    updateLeagueResponse: state.Leagues.updateLeagueResponse,
    deleteAllDraftedPlayers: state.Team.deleteAllDraftedPlayers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      createScoring,
      getAllScoringSystem,
      createLeague,
      updateLeague,
      deleteAllDraftedPlayes,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SetupDraft);
