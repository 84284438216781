import React from "react";
import { Layout } from "antd";
import Header from "../header";

const { Content } = Layout;

function PageLayout({ children, title, currentLeague }) {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header title={title} currentLeague={currentLeague} />
      <Content className="layout-content">{children}</Content>
    </Layout>
  );
}

export default PageLayout;
