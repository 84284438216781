import { Card, Col, Radio, Row, Table } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "../../assets/scss/login.scss";
import Spinner from "../../elements/spinner/spinner";
import { sortByPosition, sortByRound } from "../../helpers/helpers";
import { getTeamsByLeague } from "../../redux/actions/teams.action";
import Layout from "../common/layout";

class Teams extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filteredInfo: null,
      sortedInfo: null,
      league: props.location.state?.league,
      allTeams: [],
      loading: true,
      positionSort: false,
    };
  }

  componentDidMount() {
    const { league } = this.state;
    const { getTeamsByLeague } = this.props;
    if (!this.state.league) {
      this.props.history.push("/");
    } else {
      const body = {
        league_id: league.league_id,
        pagination: {
          pageNo: 1,
          limit: 100,
        },
      };
      getTeamsByLeague(body);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { teamsByLeague } = nextProps;
    let { allTeams } = this.state;
    if (teamsByLeague?.status === "success") {
      allTeams = teamsByLeague.teams;
    }
    this.setState({ allTeams, loading: false });
  }

  handleChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };
  onNavigation = (page, league) => {
    const { history } = this.props;
    if (league) {
      history.push(page, { league: league });
    } else {
      this.showModal();
    }
  };

  onSorting = (e) => {
    let { positionSort } = this.state;
    positionSort = e.target.value === "pos";
    this.setState({ positionSort });
  };

  renderHeader = () => {
    return (
      <Row justify="end" className="mb-10">
        <Radio.Group onChange={(e) => this.onSorting(e)} defaultValue="round">
          <Radio.Button value="round">By Round</Radio.Button>
          <Radio.Button value="pos">By Position</Radio.Button>
        </Radio.Group>
      </Row>
    );
  };

  renderTableView = (data) => {
    const { sortedInfo, positionSort } = this.state;
    const tableData = positionSort
      ? sortByPosition(data.players)
      : sortByRound(data.players);
    const columns = [
      {
        title: positionSort ? "Position" : "Round",
        dataIndex: positionSort ? "positionTitle" : "round_id",
        key: positionSort ? "positionTitle" : "round_id",
        // sorter: (a, b) => a.keyValue.localeCompare(b.keyValue),
        // sortOrder: sortedInfo?.columnKey === "keyValue" && sortedInfo?.order,
        ellipsis: true,
        width: 70,
      },
      {
        title: "PLAYERS",
        dataIndex: "keyValue",
        key: "keyValue",
        // sorter: (a, b) => a.keyValue.localeCompare(b.keyValue),
        // sortOrder: sortedInfo?.columnKey === "keyValue" && sortedInfo?.order,
        ellipsis: true,
      },
      {
        title: "POINTS",
        dataIndex: "player_points",
        key: "player_points",
        // sorter: (a, b) => a.player_points - b.player_points,
        // sortOrder: sortedInfo?.columnKey === "player_points" && sortedInfo?.order,
        width: 90,
      },
    ];

    return (
      <Col
        xs={22}
        sm={15}
        md={11}
        lg={7}
        xl={7}
        style={{ display: "inline-block" }}
      >
        <Card
          title={data.name}
          style={{
            height: "calc(100% - 10px)",
            border: data.is_your_team ? "1px solid #c33" : "none",
          }}
          headStyle={{
            textAlign: "center",
            backgroundColor: data.is_your_team && "#c33",
            color: data.is_your_team && "white",
          }}
        >
          <div className="blue-card">
            <Row gutter={16}>
              <Col flex={1}>
                <span>Starter points: {data.point_starters}</span>
              </Col>
              <Col flex={1}>
                <span>Total points: {data.total_points}</span>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col flex={1}>
                <span>Starter Rank: {data.rank_starters}</span>
              </Col>
              <Col flex={1}>
                <span>Total Rank: {data.total_ranks}</span>
              </Col>
            </Row>
          </div>

          <Table
            style={{ marginTop: 12 }}
            size="small"
            dataSource={tableData}
            columns={columns}
            onChange={this.handleChange}
            pagination={false}
          />
        </Card>
      </Col>
    );
  };

  render() {
    const { loading, allTeams, league } = this.state;
    return (
      <Layout title="Teams" currentLeague={league}>
        {this.renderHeader()}

        {loading ? (
          <Spinner />
        ) : (
          <Row
            gutter={4}
            className="scrolling-wrapper"
            style={{ flexWrap: "nowrap" }}
          >
            {allTeams.map((data) => {
              return this.renderTableView(data);
            })}
          </Row>
        )}
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    teamsByLeague: state.Team.teamsByLeague,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getTeamsByLeague }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Teams);
