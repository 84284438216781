import URL from "../../config/routes";
import types from "./action-types";
import axiosCall from "./index";

export const getAllLeagues = (data) => {
  const path = URL.GET_ALL_LEAGUES;
  const responseType = types.GET_ALL_LEAGUES;
  return axiosCall("post", path, responseType, data);
};

export const createLeague = (data) => {
  const path = URL.CREATE_LEAGUE_WITH_TEAMS;
  const responseType = types.CREATE_LEAGUE_WITH_TEAMS;
  return axiosCall("post", path, responseType, data);
};

export const updateLeague = (data) => {
  const path = URL.UPDATE_LEAGUE_WITH_TEAMS;
  const responseType = types.UPDATE_LEAGUE_WITH_TEAMS;
  return axiosCall("post", path, responseType, data);
};

export const deleteLeague = (data) => {
  const path = URL.DELETE_LEAGUE_WITH_ID;
  const responseType = types.DELETE_LEAGUE;
  return axiosCall("put", path, responseType, data);
}
