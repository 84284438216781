import types from "../actions/action-types";

export default (state = [], action) => {
  switch (action.type) {
    case types.GET_ALL_AVALIABLE_PLAYERS:
    case `${types.GET_ALL_AVALIABLE_PLAYERS}_REJECTED`:
      return {
        ...state,
        allPlayers: action.updatePayload,
      };

    case types.GET_LEAGUE_AVALIABLE_PLAYERS:
    case `${types.GET_LEAGUE_AVALIABLE_PLAYERS}_REJECTED`:
      if(action?.updatePayload?.players){
        action.updatePayload.players.forEach(player=>{
          player.player_adp    = player.player_adp.toFixed(2);
          player.player_points = player.player_points.toFixed(2);
        })
      }
      return {
        ...state,
        leaguePlayers: action.updatePayload,
      };

    case `${types.GET_ALL_DRAFTED_PLAYERS}_LOADING`:
      return {
        ...state,
        allDraftedPlayersLoading: true,
      };

    case types.GET_ALL_DRAFTED_PLAYERS:
    case `${types.GET_ALL_DRAFTED_PLAYERS}_REJECTED`:
      return {
        ...state,
        allDraftedPlayersLoading: false,
        allDraftedPlayers: action.updatePayload,
      };

    default:
      return state;
  }
};
