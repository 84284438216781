import { InfoCircleOutlined, RightOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Slider,
  Table,
  Divider,
  Tooltip,
} from "antd";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "../../assets/scss/login.scss";
import { IconsList } from "../../constants/iconsList";
import KeeperModal from "../../elements/modals/keeperModal";
import AllDraftedPlayersModal from "../../elements/modals/AllDraftedPlayersModal";
import Spinner from "../../elements/spinner/spinner";
import { sortByPosition, sortByRound } from "../../helpers/helpers";
import { getLeagueAvailablePlayers } from "../../redux/actions/player-action";
import { getKeepersByLeague } from "../../redux/actions/keeper.action";
import {
  deleteAllDraftedPlayes,
  deleteAllDraftedPlayesWithKeepers,
  getadvancedAnalytics,
  getCurrentLoopDetails,
  getmakeRecommedation,
  getmockToYourNextPick,
  getPlayerByTeam,
  getselectDraftPlayer,
  getsimulateToEnd,
  getTeamsByLeague,
  getUndoLastPick,
} from "../../redux/actions/teams.action";
import { isLoggedIn } from "../../services/auth";
import Layout from "../common/layout";
const { Option } = Select;

const commonColumns = [
  {
    title: "Best 3 picks this round By Position",
    className: "border-left",
    children: [
      {
        // title: "Positions",
        dataIndex: "fantasyPosition",
        width: 40,
        // sorter: (a, b) => a.pos.localeCompare(b.pos),
        render: (text, row, index) => {
          if (index % 4 === 0) {
            return {
              children: text,
              props: {
                rowSpan: 4,
              },
            };
          }
          return {
            children: text,
            props: {
              rowSpan: 0,
            },
          };
        },
      },
      {
        title: "Players",
        dataIndex: "name",
        width: 120,
        render: (text, row, index) => {
          return {
            props: {
              style: row.isRecommendedPlayer && { background: "#f9ebeb" },
            },
            children: <div>{text}</div>,
          };
        },
        // sorter: (a, b) => a.name.localeCompare(b.name),
      },
      {
        title: "Team",
        dataIndex: "team",
        width: 60,
        render: (text, row, index) => {
          return {
            props: {
              style: row.isRecommendedPlayer && { background: "#f9ebeb" },
            },
            children: <div>{text}</div>,
          };
        },
        // sorter: (a, b) => a.team.localeCompare(b.team),
      },
      {
        title: "BYE",
        dataIndex: "byeWeek",
        width: 50,
        render: (text, row, index) => {
          return {
            props: {
              style: row.isRecommendedPlayer && { background: "#f9ebeb" },
            },
            children: <div>{text}</div>,
          };
        },
        // sorter: (a, b) => a.bye - b.bye,
      },
      {
        title: "ADP",
        dataIndex: "player_adp",
        width: 50,
        render: (text, row, index) => {
          return {
            props: {
              style: row.isRecommendedPlayer && { background: "#f9ebeb" },
            },
            children: <div>{text}</div>,
          };
        },
        // sorter: (a, b) => a.player_adp - b.player_adp,
      },
      {
        title: "Points",
        dataIndex: "player_points",
        width: 65,
        render: (text, row, index) => {
          return {
            props: {
              style: row.isRecommendedPlayer && { background: "#f9ebeb" },
            },
            children: <div>{text}</div>,
          };
        },
        // sorter: (a, b) => a.player_points - b.player_points,
        className: "border-left",
      },
    ],
  },
  {
    title: "Expected Best player if you wait",
    className: "border-left",
    children: [
      {
        // title: "Positions",
        dataIndex: "fantasyPosition2",
        width: 40,
        // sorter: (a, b) => a.pos.localeCompare(b.pos),
        render: (text, row, index) => {
          if (index % 4 === 0) {
            return {
              children: text,
              props: {
                rowSpan: 4,
              },
            };
          }
          return {
            children: text,
            props: {
              rowSpan: 0,
            },
          };
        },
      },
      {
        className: "table-font-12",
        width: 100,
        render: (text, row, index) => {
          if (index % 4 === 0) {
            return {
              children: "NOW",
            };
          } else if (index % 4 === 1) {
            return {
              children: "Wait 1 pick",
            };
          } else if (index % 4 === 2) {
            return {
              children: "Wait 2 picks",
            };
          } else if (index % 4 === 3) {
            return {
              children: "Wait 3 picks",
            };
          }
        },
      },
      {
        title: "Players",
        dataIndex: "name2",
        width: 120,
        // sorter: (a, b) => a.name.localeCompare(b.name),
      },
      {
        title: "Team",
        dataIndex: "team2",
        width: 60,
        // sorter: (a, b) => a.team.localeCompare(b.team),
      },
      {
        title: "BYE",
        dataIndex: "byeWeek2",
        width: 50,
        // sorter: (a, b) => a.bye - b.bye,
      },
      {
        title: "ADP",
        dataIndex: "player_adp2",
        width: 50,
        // sorter: (a, b) => a.adp - b.adp,
      },
      {
        title: "Points",
        dataIndex: "player_points2",
        width: 70,
        // sorter: (a, b) => a.player_points - b.player_points,
        className: "border-left",
      },
    ],
  },
  {
    title: "Points",
    className: "table-col",
    children: [
      {
        title: "Points Lost By Waiting",
        dataIndex: "ctw",
        className: "table-col",
        width: 65,
      },
    ],
  },
];

class Draft extends Component {
  constructor(props) {
    super(props);
    this.state = {
      league: props.location.state?.league,
      filteredInfo: null,
      user: null,
      sortedInfo: null,
      searchPlayer: "",
      advanced: false,
      depth: 3,
      recommend: null,
      loadingRecommend: false,
      leaguePlayers: [],
      draftedPlayers: [],
      loading: true,
      loopDetails: null,
      loadingDraftPlayer: true,
      visible: false,
      teamOptions: [],
      defaultName: "",
      loadingPickandSimulate: false,
      teamToShow: null,
      mockToNextPick: false,
      filters: ["QB", "TE", "RB", "DST", "WR", "K", "Up", "PH"],
      checkAll: true,
      advancedAnalyticsData: null,
      loadingAdvanceAnalytics: false,
      keeperModal: false,
      positionSort: false,
      resetKeeperModal: false,
      resetDraftModal: false,
      filterOnlyUpside: false,
      isHansen: false
    };
  }

  async componentDidMount() {
    const user = await isLoggedIn();
    this.setState({ user });
    if (!this.state.league) {
      this.props.history.push("/");
    } else {
      this.getAllData();
    }
  }
  componentDidUpdate(prevProps) {
    const {
      makeRecommedation,
      undoLastPick,
      getAllCurrentLoopDetails,
      selectedDraftPlayer,
      simulateToEnd,
      deleteAllDraftedPlayers,
      mockToYourNextPick,
      teamsByLeague,
      leaguePlayers,
      keeperListData,
      playerByTeam,
    } = this.props;

    if (prevProps.getAllCurrentLoopDetails) {
      if (
        prevProps.getAllCurrentLoopDetails?.current_round !==
        getAllCurrentLoopDetails?.current_round
      ) {
        if (getAllCurrentLoopDetails?.current_round === null) {
          message.success("Draft Complete!");
        }
      }
    }

    if (prevProps.keeperListData !== keeperListData) {
      if (!this.state.keeperModal && keeperListData?.status === "success") {
        if (keeperListData?.keepers.length > 0) {
          this.setState({ resetKeeperModal: true });
        } else {
          this.resetDraft();
        }
      }
    }
    if (prevProps.makeRecommedation !== makeRecommedation) {
      if (makeRecommedation?.status === "success") {
        if (this.state.loadingRecommend) {
          const recommend = makeRecommedation?.recommedationPlayer.player_id;
          // message.success("Make Recommedation Successful");
          this.setState({ recommend, loadingRecommend: false });
        }
      }
    }
    if (prevProps.undoLastPick !== undoLastPick) {
      if (undoLastPick?.status === "success") {
        message.success("Undo Last Pick Successful");
        this.getAllData();
        this.setState({ loadingPickandSimulate: false });
      }

      if (undoLastPick?.message === "No Drafted Players Found!") {
        if (this.state.loadUndoLastPick) {
          message.error("No Drafted Players Found");
        }
      }
      this.setState({ loadingPickandSimulate: false });
    }

    if (prevProps.mockToYourNextPick !== mockToYourNextPick) {
      if (mockToYourNextPick?.status === "success") {
        message.success("Mocked To Your Next Pick Successfully");
        this.getAllData();
        this.setState({ loadingPickandSimulate: false });
      }
      if (
        mockToYourNextPick?.message === "All Team players are already drafted"
      ) {
        this.showModal();
        this.setState({
          allDraftPopup: false,
          loadingPickandSimulate: false,
          loading: false,
          loadingDraftPlayer: false,
        });
      }
    }
    if (prevProps.deleteAllDraftedPlayers !== deleteAllDraftedPlayers) {
      if (deleteAllDraftedPlayers?.status === "success") {
        this.getAllData();

        message.success("All Draft Players Deleted Successfully");
        this.setState({ loading: true, loadingDraftPlayer: true });

        this.setState({ loadingPickandSimulate: false, deleteDraft: false });
      }
    }

    if (prevProps.simulateToEnd !== simulateToEnd) {
      if (simulateToEnd?.status === "success") {
        // message.success("Simulated To End Successfully");
        this.getAllData();
        this.setState({ loading: true, loadingDraftPlayer: true });
        this.setState({ loadingPickandSimulate: false });
      }

      if (simulateToEnd?.status === "error") {
        message.success("There is no draft loop found");
        this.setState({
          loadingPickandSimulate: false,
        });
      }
    }
    if (prevProps.getAllCurrentLoopDetails !== getAllCurrentLoopDetails) {
      if (getAllCurrentLoopDetails?.status === "success") {
        const { teamToShow } = this.state;

        if (teamToShow === null) {
          if (
            getAllCurrentLoopDetails &&
            getAllCurrentLoopDetails.current_team &&
            getAllCurrentLoopDetails.current_team.team_id
          ) {
            const idTeamId = getAllCurrentLoopDetails.current_team.team_id;

            this.onTeamSelect(idTeamId);
          } else {
            this.setState({ loadingDraftPlayer: false });
          }
        } else {
          this.onTeamSelect(teamToShow);
        }
      }

      this.setState({
        loopDetails: getAllCurrentLoopDetails,
        loadingPickandSimulate: false,
      });
    }

    if (prevProps.teamsByLeague !== teamsByLeague) {
      if (teamsByLeague?.status === "success") {
        let teamOptions = [];
        const { teamToShow } = this.state;
        teamsByLeague.teams.forEach((data) => {
          teamOptions.push(
            <Option value={data.team_id}>
              {data.name}
              {data.is_your_team ? " (YOU)" : ""}
            </Option>
          );
        });
        let selectTeam =
          teamsByLeague.teams &&
          teamsByLeague.teams.filter((data) => data.is_your_team === 1);
        if (teamToShow) {
          if (selectTeam[0] && selectTeam[0].team_id) {
            this.onTeamSelect(selectTeam[0].team_id);
          }
        }
        this.setState({ allTeams: teamsByLeague.teams, teamOptions });
      }
    }
    if (prevProps.leaguePlayers !== leaguePlayers) {
      if (leaguePlayers?.status === "success") {
        this.setState({ leaguePlayers: leaguePlayers.players, loading: false });
      }
    }

    if (prevProps.selectedDraftPlayer !== selectedDraftPlayer) {
      if (selectedDraftPlayer?.status === "success") {
        const { league } = this.state;
        this.triggerGetAllAvailablePlayers();
        const { teamToShow, loopDetails } = this.state;
        const idTeamId = loopDetails.current_team.team_id;
        if (teamToShow) {
          if (idTeamId === teamToShow) {
            this.onTeamSelect(idTeamId);
          }
        }
        this.getCurrentLoopDetailsFuc(league);
      }

      if (
        selectedDraftPlayer?.message === "All Team players are already drafted"
      ) {
        this.showModal();
        this.setState({
          allDraftPopup: false,
          loadingPickandSimulate: false,
          loading: false,
          loadingDraftPlayer: false,
        });
      }
    }
    if (prevProps.playerByTeam !== playerByTeam) {
      if (playerByTeam?.status === "success") {
        this.setState({ draftedPlayers: playerByTeam.teams.players });
      }

      this.setState({ loadingDraftPlayer: false });
    }
  }

  UNSAFE_componentWillReceiveProps(nextprops) {
    const {
      advancedAnalytics,
    } = nextprops;

    const renameKeys = (keysMap, obj) =>
      Object.keys(obj).reduce(
        (acc, key) => ({
          ...acc,
          ...{ [keysMap[key] || key]: obj[key] },
        }),
        {}
      );

    if (
      this.props.advancedAnalytics !== advancedAnalytics &&
      advancedAnalytics?.status === "success"
    ) {
      let advancedOption = [];

      const keysMap = {
        fantasyPosition: "fantasyPosition2",
        name: "name2",
        team: "team2",
        byeWeek: "byeWeek2",
        player_adp: "player_adp2",
        player_points: "player_points2",
        isRecommendedPlayer: "isRecommendedPlayer2",
      };

      let loop = 0;

      [0, 1, 2, 3].forEach(function (i) {
        let expectedpick =
          advancedAnalytics.expectBestPickPlayers.QB?.[i] &&
          renameKeys(keysMap, advancedAnalytics.expectBestPickPlayers.QB[i]);
        let bestpick =
          i > 0 ? advancedAnalytics.bestPickPlayers.QB?.[i - 1] : {};
        let ctw = i > 0 ? advancedAnalytics.costOfWait[loop++] : null;
        advancedOption.push({
          ...expectedpick,
          ...bestpick, // isRecommendedPlayer belongs to bestPickPlayers
          fantasyPosition: "QB",
          fantasyPosition2: "QB",
          ctw: ctw,
        });
      });
      [0, 1, 2, 3].forEach(function (i) {
        let expectedpick =
          advancedAnalytics.expectBestPickPlayers.RB?.[i] &&
          renameKeys(keysMap, advancedAnalytics.expectBestPickPlayers.RB[i]);
        let bestpick =
          i > 0 ? advancedAnalytics.bestPickPlayers.RB?.[i - 1] : {};
        let ctw = i > 0 ? advancedAnalytics.costOfWait[loop++] : null;
        advancedOption.push({
          ...expectedpick,
          ...bestpick,
          fantasyPosition: "RB",
          fantasyPosition2: "RB",
          ctw: ctw,
          setBorder: i === 0 ? true : false,
        });
      });
      [0, 1, 2, 3].forEach(function (i) {
        let expectedpick =
          advancedAnalytics.expectBestPickPlayers.WR?.[i] &&
          renameKeys(keysMap, advancedAnalytics.expectBestPickPlayers.WR[i]);
        let bestpick =
          i > 0 ? advancedAnalytics.bestPickPlayers.WR?.[i - 1] : {};
        let ctw = i > 0 ? advancedAnalytics.costOfWait[loop++] : null;
        advancedOption.push({
          ...expectedpick,
          ...bestpick,
          fantasyPosition: "WR",
          fantasyPosition2: "WR",
          ctw: ctw,
          setBorder: i === 0 ? true : false,
        });
      });
      [0, 1, 2, 3].forEach(function (i) {
        let expectedpick =
          advancedAnalytics.expectBestPickPlayers.TE?.[i] &&
          renameKeys(keysMap, advancedAnalytics.expectBestPickPlayers.TE[i]);
        let bestpick =
          i > 0 ? advancedAnalytics.bestPickPlayers.TE?.[i - 1] : {};
        let ctw = i > 0 ? advancedAnalytics.costOfWait[loop++] : null;
        advancedOption.push({
          ...expectedpick,
          ...bestpick,
          fantasyPosition: "TE",
          fantasyPosition2: "TE",
          ctw: ctw,
          setBorder: i === 0 ? true : false,
        });
      });
      [0, 1, 2, 3].forEach(function (i) {
        let expectedpick =
          advancedAnalytics.expectBestPickPlayers.DST?.[i] &&
          renameKeys(keysMap, advancedAnalytics.expectBestPickPlayers.DST[i]);
        let bestpick =
          i > 0 ? advancedAnalytics.bestPickPlayers.DST?.[i - 1] : {};
        let ctw = i > 0 ? advancedAnalytics.costOfWait[loop++] : null;
        advancedOption.push({
          ...expectedpick,
          ...bestpick,
          fantasyPosition: "DST",
          fantasyPosition2: "DST",
          ctw: ctw,
          setBorder: i === 0 ? true : false,
        });
      });
      [0, 1, 2, 3].forEach(function (i) {
        let expectedpick =
          advancedAnalytics.expectBestPickPlayers.K?.[i] &&
          renameKeys(keysMap, advancedAnalytics.expectBestPickPlayers.K[i]);
        let bestpick =
          i > 0 ? advancedAnalytics.bestPickPlayers.K?.[i - 1] : {};
        let ctw = i > 0 ? advancedAnalytics.costOfWait[loop++] : null;
        advancedOption.push({
          ...expectedpick,
          ...bestpick,
          fantasyPosition: "K",
          fantasyPosition2: "K",
          ctw: ctw,
          setBorder: i === 0 ? true : false,
        });
      });

      this.setState({
        advancedAnalyticsData: advancedOption,
        loadingAdvanceAnalytics: false,
      });
    }
  }

  triggerGetAllAvailablePlayers = () => {
    const { league } = this.state;
    this.setState({ loading: true });
    const { getLeagueAvailablePlayers } = this.props;
    const body = {
      search: "",
      pagination: {
        pageNo: 1,
      },
    };
    body.league_id = league?.league_id;
    getLeagueAvailablePlayers(body);
  };

  onTeamSelect = (value) => {
    this.setState({ loadingDraftPlayer: true, teamToShow: value });
    const { getPlayerByTeam } = this.props;
    getPlayerByTeam(value);
  };

  handleChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  resetKeeper = () => {
    const { league } = this.state;
    this.setState({ resetKeeperModal: false });
    if (league) {
      this.setState({
        visible: false,
        loadingPickandSimulate: true,
        deleteDraft: true,
      });
      const { deleteAllDraftedPlayesWithKeepers } = this.props;
      const body = {
        league_id: league.league_id,
      };
      deleteAllDraftedPlayesWithKeepers(body);
    }
  };

  showCloseKeeperModal = (type) => {
    if (type === "OK") {
      this.setState({ resetDraftModal: false });
      const { league } = this.state;
      const body = {
        league_id: league.league_id,
        pagination: {
          pageNo: 1,
          limit: 100,
        },
      };
      this.props.getKeepersByLeague(body);
    } else {
      this.setState({ resetDraftModal: false });
    }
  };

  resetDraft = () => {
    const { league } = this.state;
    this.setState({ resetKeeperModal: false });
    if (league) {
      this.setState({
        visible: false,
        loadingPickandSimulate: true,
        deleteDraft: true,
      });
      const { deleteAllDraftedPlayes } = this.props;
      const body = {
        league_id: league.league_id,
      };
      deleteAllDraftedPlayes(body);
    }
  };

  handleOk = () => {
    this.setState({ resetDraftModal: true });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  onNavigation = (page, league) => {
    const { history } = this.props;
    if (league) {
      if (page === "setup-draft") {
        history.push("setup-draft", { league: league, edit: true });
      } else {
        history.push(page, { league: league });
      }
    } else {
    }
  };

  UndoLastPick = (league) => {
    if (league) {
      this.setState({ loadUndoLastPick: true });
      const { getUndoLastPick } = this.props;
      const body = {
        league_id: league.league_id,
      };
      getUndoLastPick(body);
    } else {
    }
  };

  simulateToEndFuc = (league, user, loopDetails) => {
    this.setState({ loadingPickandSimulate: true });
    const { getsimulateToEnd } = this.props;
    const body = {
      league_id: league.league_id,
      uid: user.uid,
      isHansen: this.state.isHansen,
      starter_depth: this.state.depth,
      current_team_id: loopDetails.current_team.team_id,
      current_round: loopDetails.current_round,
    };
    getsimulateToEnd(body);
  };
  getmockToYourNextPickFuc = (league, loopDetails) => {
    if (league) {
      const { getmockToYourNextPick } = this.props;
      if (
        loopDetails &&
        loopDetails.current_team &&
        loopDetails.current_team.team_id
      ) {
        if (loopDetails.current_team.is_your_team) {
          this.showInfo();
        } else {
          this.setState({ mockToNextPick: true });
          const body = {
            league_id: league.league_id,
            current_team_id: loopDetails.current_team.team_id,
            current_round: loopDetails.current_round,
          };
          getmockToYourNextPick(body);
        }
      } else {
        // this.showModal();
        message.error("Drafting already completed!");
      }
    } else {
    }
  };

  showInfo = () => {
    Modal.info({
      title: "Info",
      content: (
        <div>
          <p>It's your turn now</p>
        </div>
      ),
      onOk() {},
    });
  };

  getAllData = () => {
    this.setState({ loading: true, loadingDraftPlayer: true });
    const { league } = this.state;
    const { getLeagueAvailablePlayers, getTeamsByLeague } = this.props;
    const body = {
      search: "",
      pagination: {},
    };
    body.league_id = league?.league_id;
    getLeagueAvailablePlayers(body);
    getTeamsByLeague(body);
    this.getCurrentLoopDetailsFuc(league);
  };

  getCurrentLoopDetailsFuc = (league) => {
    if (league) {
      this.setState({ allDraftPopup: true, getAllloop: true });
      const { getCurrentLoopDetails } = this.props;
      const body = {
        league_id: league.league_id,
      };
      getCurrentLoopDetails(body);
    } else {
    }
  };

  selectPlayer = (league, user, r, loopDetails) => {
    this.setState({ showRecommended: false, advancedAnalyticsData: null });
    if (league) {
      const { getselectDraftPlayer } = this.props;
      if (
        loopDetails &&
        loopDetails.current_team &&
        loopDetails.current_team.team_id
      ) {
        this.setState({ loading: true });
        const body = {
          league_id: league.league_id,
          current_team_id: loopDetails.current_team.team_id,
          player_id: r.player_id ?? r,
          current_round: loopDetails.current_round,
          uid: user.uid,
        };
        getselectDraftPlayer(body);
      } else {
        this.showModal();
      }
    } else {
    }
  };

  makeRecommedation = (league) => {
    const { loopDetails } = this.state;

    if (league) {
      const { getmakeRecommedation } = this.props;
      if (
        loopDetails &&
        loopDetails.current_team &&
        loopDetails.current_team.team_id
      ) {
        this.setState({ showRecommended: true, loadingRecommend: true });
        const body = {
          filter: [],
          league_id: league.league_id,
          starter_depth: this.state.depth,
          isHansen: this.state.isHansen,
          current_team_id: loopDetails.current_team.team_id,
          current_round: loopDetails.current_round,
        };
        getmakeRecommedation(body);
        this.advancedAnalyticsFunc(
          this.state.league,
          this.state.loopDetails,
          []
        );
      } else {
        this.showModal();
      }
    } else {
    }
  };

  makeRecommedationFiltered = (league) => {
    let allowedFilters = this.state.filters.filter((a) =>
      ["QB", "TE", "RB", "DST", "WR", "K"].includes(a)
    );

    const { loopDetails } = this.state;

    if (league) {
      const { getmakeRecommedation } = this.props;
      if (
        loopDetails &&
        loopDetails.current_team &&
        loopDetails.current_team.team_id
      ) {
        this.setState({ showRecommended: true, loadingRecommend: true });
        const body = {
          filter: allowedFilters,
          league_id: league.league_id,
          starter_depth: this.state.depth,
          current_team_id: loopDetails.current_team.team_id,
          current_round: loopDetails.current_round,
        };
        getmakeRecommedation(body);
        this.advancedAnalyticsFunc(
          this.state.league,
          this.state.loopDetails,
          allowedFilters
        );
      } else {
        this.showModal();
      }
    } else {
    }
  };

  advancedAnalyticsFunc = (league, loopDetails, allowedFilters) => {
    // const { loopDetails } = this.state;

    if (league) {
      const { getadvancedAnalytics: getadvancedAnalyticsFn } = this.props;
      if (
        loopDetails &&
        loopDetails.current_team &&
        loopDetails.current_team.team_id
      ) {
        this.setState({ loadingAdvanceAnalytics: true });
        const body = {
          filter: allowedFilters,
          league_id: league.league_id,
          current_team_id: loopDetails.current_team.team_id,
          current_round: loopDetails.current_round,
          starter_depth: this.state.depth,
        };
        getadvancedAnalyticsFn(body);
      }
    } else {
    }
  };

  // ChangePercentage = (marks) => {
  //   this.setState({
  //     percentage:marks
  // ;

  onSorting = async (data) => {
    let { positionSort } = this.state;
    positionSort = data.target.value === "pos";
    this.setState({ positionSort });
  };

  renderTopView = () => {
    const { league, loopDetails, advanced } = this.state;

    const checkKeepLeague = (currentLeague) => {
      if (currentLeague) {
        this.setState({ keeperModal: true });
      } else {
        this.showModal();
      }
    };

    return (
      <>
        <Row justify="space-between">
          <Col>
            <span className="sm-flex-scroll">
              <Radio.Group
                className="mr-20 mb-10"
                onChange={() => {
                  this.setState({ advanced: !advanced });
                }}
                value={advanced ? "advanced" : "default"}
              >
                <Radio.Button value="default">Available Players</Radio.Button>
                <Radio.Button value="advanced">Advanced Analytics</Radio.Button>
              </Radio.Group>
            </span>
            <span className="sm-flex-scroll">
              <Button
                className="mr-20 mb-10"
                type="primary"
                shape="round"
                onClick={() => checkKeepLeague(league)}
              >
                Keeper Input
              </Button>
              <Button
                className="mr-20 mb-10"
                type="primary"
                shape="round"
                onClick={() => this.handleOk()}
              >
                Reset Draft
              </Button>
            </span>
          </Col>
          <Col className="sm-flex-scroll">
            <Button
              onClick={() => {
                this.setState({
                  showRecommended: false,
                  advancedAnalyticsData: null,
                });
                this.UndoLastPick(league);
              }}
              className="mr-20 mb-10"
              type="primary"
              shape="round"
            >
              Undo Last Pick
            </Button>
            <Button
              onClick={() => {
                this.setState({ showRecommended: false });
                this.getmockToYourNextPickFuc(league, loopDetails);
              }}
              className="mr-20 mb-10"
              type="primary"
              shape="round"
            >
              Mock To Your Next Pick
            </Button>
            <Button
              onClick={() => {
                if (
                  league &&
                  loopDetails &&
                  loopDetails.current_team &&
                  loopDetails.current_team.team_id
                )
                  this.setState({ showConfirmSimulateModal: true });
                else {
                  message.error("Drafting already completed!");
                }
              }}
              className="mb-10"
              type="primary"
              shape="round"
            >
              Simulate To End
            </Button>
          </Col>
        </Row>
      </>
    );
  };

  fiterView = () => {
    const { leaguePlayers, recommend, league, filters } = this.state;
    let user = leaguePlayers.filter((data) => data.player_id === recommend);
    user = user[0];
    const marks = {
      1: {
        label: <h4 className="fw-2">Starters</h4>,
      },
      2: "2",
      3: "3",
      4: "4",
      5: {
        label: <h4 className="fw-2">Depth</h4>,
      },
    };

    const allOptions = ["QB", "TE", "RB", "DST", "WR", "K"];

    const onCheckAllChange = (e) => {
      this.setState({
        filters: e.target.checked ? [...allOptions,"PH"] : [],
        indeterminate: false,
        checkAll: e.target.checked,
      });
    };

    const onHandleFilter = (checkedList) => {
      this.setState({
        filters: checkedList,
        indeterminate:
          !!checkedList.length && checkedList.length < allOptions.length,
        checkAll: checkedList.length === allOptions.length,
      });
    };

    return (
      <Fragment>
        <Row align="middle">
          <Col xs={24} sm={24} md={12}>
            <Row style={{ marginTop: 8 }}>
              <Col span={24}>
                <Checkbox
                  indeterminate={this.state.indeterminate}
                  onChange={onCheckAllChange}
                  checked={this.state.checkAll}
                >
                  Show All Positions
                </Checkbox>
              </Col>
            </Row>
            <Checkbox.Group
              value={filters}
              onChange={(value) => onHandleFilter(value)}
              style={{ width: "100%" }}
            >
              <Row style={{ marginTop: 8 }}>
                <Col span={12}>
                  <Checkbox value="QB">QB</Checkbox>
                </Col>
                <Col span={12}>
                  <Checkbox value="TE">TE</Checkbox>
                </Col>
              </Row>
              <Row style={{ marginTop: 8 }}>
                <Col span={12}>
                  <Checkbox value="RB">RB</Checkbox>
                </Col>
                <Col span={12}>
                  <Checkbox value="DST">DST</Checkbox>
                </Col>
              </Row>
              <Row style={{ marginTop: 8 }}>
                <Col span={12}>
                  <Checkbox value="WR">WR</Checkbox>
                </Col>
                <Col span={12}>
                  <Checkbox value="K">K</Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
            <Row style={{ marginTop: 30 }}>
              <Col span={24}>
                <Checkbox
                  onChange={(e) =>
                    e.target.checked
                      ? this.setState({ filterOnlyUpside: true })
                      : this.setState({ filterOnlyUpside: false })
                  }
                  checked={this.state.filterOnlyUpside}
                >
                  Show Only Upside / Target / League Winner
                </Checkbox>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={12} style={{ marginTop: 10 }}>
            {/* <h4>Recommendation To Focus On</h4> */}
            <Divider orientation="left">Recommendation To Focus On</Divider>
            <Row justify="center">
              <Col span={24}>
                <Slider
                  marks={marks}
                  defaultValue={3}
                  min={1}
                  max={5}
                  onChange={(value) => this.setState({ depth: value })}
                />
              </Col>
            </Row>
            <Row justify="center" style={{ marginTop: 10 }}>
              <Col span={24}>
                <Divider orientation="left">Make Recommendation for</Divider>
                <Button
                  className="mr-20 mb-10"
                  type="primary"
                  shape="round"
                  disabled={
                    this.state.loopDetails?.current_team?.is_your_team
                      ? false
                      : true
                  }
                  onClick={() => {
                    this.makeRecommedation(league);
                  }}
                >
                  All Positions
                </Button>
                <Button
                  type="primary"
                  shape="round"
                  disabled={
                    !this.state.indeterminate ||
                    (this.state.loopDetails?.current_team?.is_your_team
                      ? false
                      : true) ||
                    this.state.isHansen
                  }
                  onClick={() => {
                    this.makeRecommedationFiltered(league);
                  }}
                >
                  Selected Positions
                </Button>
              </Col>

            </Row>

          </Col>

        </Row>
        <Row className='blue-card'>
          <Col xs={24}>
            <Checkbox name='isHansen' onClick={e => this.setState({isHansen : !this.state.isHansen })} checked={this.state.isHansen}>Hansen's Hints Mode</Checkbox>
            <Tooltip title='Select this option if you want to have a combination of analytics with John Hansen’s drafting knowledge built into the recommendations.'>
              <InfoCircleOutlined />
            </Tooltip>
          </Col>
      </Row>
        {/* <GaugeChart
          textColor={"black"}
          id="gauge-chart2"
          nrOfLevels={20}
          percent={this.state.percentage}
        /> */}

        <br />

        {this.state.showRecommended &&
          (recommend &&
          leaguePlayers &&
          !this.state.loadingRecommend &&
          !this.state.loading ? (
            <Fragment>
              <h3>Recommended Player</h3>
              <Table
                className="recomm-player-table"
                showHeader={false}
                pagination={false}
                onRow={() => ({
                  onDoubleClick: () =>
                    this.selectPlayer(
                      league,
                      this.state.user,
                      recommend,
                      this.state.loopDetails
                    ),
                })}
                columns={[
                  {
                    title: "Positions",
                    dataIndex: "fantasyPosition",
                    key: "fantasyPosition",
                    align: "centre",
                    width: 90,
                  },
                  {
                    title: "Players",
                    dataIndex: "name",
                    key: "name",
                    align: "centre",
                  },
                  {
                    title: "Team",
                    dataIndex: "team",
                    key: "team",
                    align: "centre",
                    width: 80,
                  },
                  {
                    title: "Bye",
                    dataIndex: "byeWeek",
                    key: "byeWeek",
                    align: "centre",
                    width: 50,
                  },
                  {
                    title: "ADP",
                    dataIndex: "player_adp",
                    key: "player_adp",
                    align: "centre",
                    width: 80,
                  },
                  {
                    title: "Points",
                    dataIndex: "player_points",
                    key: "player_points",
                    align: "centre",
                    width: 80,
                  },
                  {
                    width: 80,
                    render: () => {
                      return (
                        <Button
                          size="small"
                          icon={<RightOutlined />}
                          style={{
                            borderColor: "rgba(0, 0, 0, 0.85)",
                            color: "rgba(0, 0, 0, 0.85)",
                            fontWeight: "bold",
                          }}
                          onClick={() => {
                            this.selectPlayer(
                              league,
                              this.state.user,
                              recommend,
                              this.state.loopDetails
                            );
                          }}
                        >
                          Pick
                        </Button>
                      );
                    },
                  },
                ]}
                dataSource={[
                  {
                    fantasyPosition: user?.fantasyPosition,
                    name: user?.name,
                    team: user?.team,
                    byeWeek: user?.byeWeek,
                    player_adp: user?.player_adp,
                    player_points: user?.player_points,
                  },
                ]}
                size="small"
              />
              <br />
            </Fragment>
          ) : (
            <div>
              <Spinner />
            </div>
          ))}
      </Fragment>
    );
  };

  renderElement(type) {
    const icon = IconsList(type);
    if (type) return <span>{icon}</span>;
    return <span></span>;
  }

  playersView = () => {
    const {

      searchPlayer,
      recommend,
      loading,
      leaguePlayers,
      league,
      user,
      loopDetails,
      filters,
    } = this.state;
    const columns = [
      {
        title: "Positions",
        dataIndex: "fantasyPosition",
        key: "fantasyPosition",
        align: "centre",
        width: 90,
        sorter: (a, b) => a.fantasyPosition.localeCompare(b.fantasyPosition),
        // sorter: (a, b) => (a.positions < b.pos ? -1 : a.pos > b.pos ? 1 : 0),
        // sortOrder: sortedInfo?.columnKey === "pos" && sortedInfo?.order,
        render: (text, record, index) => {
          const icon =
            record.isUp === 1
              ? "up"
              : record.isLeagueWinner === 1
              ? "winner"
              : record.isTarget === 1
              ? "aim"
              : null;

          return (
            <Row justify="space-around">
              <Col span={8} className='available-players-position-column'><span>{record.fantasyPosition}</span></Col>
              <Col span={6}>{this.renderElement(icon)}</Col>
              <Col span={4}>{record.fantasyPosition !=='DST' && record.fantasyPosition !== 'PH' && (!record.experience || record.experience === 'null' || record.experience === '0') && <span style={{color:'red'}}>R</span>}</Col>
            </Row>
          );
        },
      },
      {
        title: "Players",
        dataIndex: "name",
        key: "name",
        align: "centre",
        width: 150,
        sorter: (a, b) => a.name.localeCompare(b.name),
        // sortOrder: sortedInfo?.columnKey === "name" && sortedInfo?.order,
      },
      {
        title: "Team",
        dataIndex: "team",
        key: "team",
        align: "centre",
        width: 80,
        sorter: (a, b) => a.team - b.team,
        // sortOrder: sortedInfo?.columnKey ===  "team" && sortedInfo?.order,
      },
      {
        title: "Bye",
        dataIndex: "byeWeek",
        key: "byeWeek",
        align: "centre",
        width: 50,
        sorter: (a, b) => a.byeWeek - b.byeWeek,
        // sortOrder: sortedInfo?.columnKey === "bye" && sortedInfo?.order,
      },
      {
        title: "ADP",
        dataIndex: "player_adp",
        key: "player_adp",
        align: "centre",
        width: 80,
        sorter: (a, b) => a.player_adp - b.player_adp,
        // sortOrder: sortedInfo?.columnKey === "player_adp" && sortedInfo?.order,
      },
      {
        title: "Points",
        dataIndex: "player_points",
        key: "player_points",
        align: "centre",
        width: 80,
        sorter: (a, b) => a.player_points - b.player_points,
        // sortOrder:
        //   sortedInfo?.columnKey === "player_points" && sortedInfo?.order,
      },
      {
        width: 80,
        render: (r) => {
          return (
            <Button
              size="small"
              icon={<RightOutlined />}
              style={{
                borderColor: "rgba(0, 0, 0, 0.85)",
                color: "rgba(0, 0, 0, 0.85)",
                fontWeight: "bold",
              }}
              onClick={() => {
                this.selectPlayer(league, user, r, loopDetails);
              }}
            >
              Pick
            </Button>
          );
        },
      },
    ];

    var filterData = leaguePlayers.filter(
      (a) =>
        (filters.includes(a.fantasyPosition)) &&
        a.name.toLocaleLowerCase().includes(searchPlayer.toLocaleLowerCase())
    );
    if (this.state.filterOnlyUpside) {
      // this.setState({ scoringModal: true });
      let UpsideTargetLeagueWinnerData = [];
      leaguePlayers.forEach((data) => {
        if (
          data.isUp === 1 ||
          data.isLeagueWinner === 1 ||
          data.isTarget === 1
        ) {
          UpsideTargetLeagueWinnerData.push(data);
        }
      });
      filterData = filterData.filter((value) =>
        UpsideTargetLeagueWinnerData.includes(value)
      );
      filterData = Array.from(new Set(filterData));

      // this.setState({ leaguePlayers : fliterTeam});
    }

    // let userRecommend = leaguePlayers.filter(
    //   (data) => data.player_id === recommend
    // );
    // userRecommend = userRecommend[0];
    return (
      <Fragment>
        <Row justify="space-between">
          <Col>
            <h3>Available Players</h3>
          </Col>
          <Col span={12}>
            <Input
              onChange={(e) => this.setState({ searchPlayer: e.target.value })}
              placeholder="Find a player..."
            />
          </Col>
        </Row>
        <Table
          rowClassName={(record, index) =>
            this.state.showRecommended && record.player_id === recommend
              ? "table-row-dark"
              : ""
          }
          size={"small"}
          rowKey={(data) => data.player_id}
          scroll={{ x: 500, y: 350 }}
          pagination={false}
          loading={loading}
          dataSource={filterData}
          columns={columns}
          onRow={(r) => ({
            onDoubleClick: () =>
              this.selectPlayer(league, user, r, loopDetails),
          })}
          onChange={this.handleChange}
        />
      </Fragment>
    );
  };

  advancedView = () => {
    const {

      advancedAnalyticsData,
      loadingAdvanceAnalytics,
    } = this.state;
    // let filterData =
    //   advancedAnalyticsData &&
    //   advancedAnalyticsData.filter((a) => filters.includes(a.pos));
    return (
      <Fragment>
        <Row span={24}>
          <Table
            size={"small"}
            bordered
            rowKey={(data) => data.players}
            scroll={{ x: true, y: 450 }}
            style={{ width: "100%" }}
            pagination={false}
            dataSource={advancedAnalyticsData}
            loading={loadingAdvanceAnalytics}
            columns={commonColumns}
            onChange={this.handleChange}
            rowClassName={(record) =>
              record.setBorder ? "row-top-border" : ""
            }
          />
        </Row>
      </Fragment>
    );
  };

  renderLeftView = () => {
    const { advanced } = this.state;
    const colSpan = advanced ? 16 : 12;
    return (
      <Col sm={24} md={colSpan}>
        <Card bordered={false}>
          {this.fiterView()}
          {advanced ? this.advancedView() : this.playersView()}
        </Card>
      </Col>
    );
  };

  renderRightView = () => {
    const {
      advanced,
      teamOptions,
      draftedPlayers,
      loadingDraftPlayer,
      loopDetails,
      allTeams,
      teamToShow,
      positionSort,
    } = this.state;
    const tableData = positionSort
      ? sortByPosition(draftedPlayers)
      : sortByRound(draftedPlayers);
    
    // draftedPlayers
    let selectTeam =
      allTeams && allTeams.filter((data) => data.team_id === teamToShow);
    const colSpan = advanced ? 8 : 12;
    const columns = [
      {
        title: positionSort ? "POS" : "Round",
        dataIndex: positionSort ? "positionTitle" : "round_id",
        width: 70,
        // sorter: (a, b) => a.pos.localeCompare(b.pos),
      },
      {
        title: "Position",
        dataIndex: "fantasyPosition",
        width: 70,
        // sorter: (a, b) => a.pos.localeCompare(b.pos),
        render: (text, record) => {
          return (
            <Row>
              <span>{record.fantasyPosition}</span>
            </Row>
          );
        },
      },
      {
        title: "Players",
        dataIndex: "name",
        // sorter: (a, b) => a.name.localeCompare(b.name),
      },
      {
        title: "Team",
        dataIndex: "team",
        width: 80,
        // sorter: (a, b) => a.team.localeCompare(b.team),
      },
      {
        title: "Bye",
        dataIndex: "byeWeek",
        width: 50,
        // sorter: (a, b) => a.bye - b.bye,
      },
      {
        title: "ADP",
        dataIndex: "player_adp",
        width: 80,
        // sorter: (a, b) => a.player_adp - b.player_adp,
      },
      {
        title: "Points",
        dataIndex: "player_points",
        width: 80,
        // sorter: (a, b) => a.player_points - b.player_points,
      },
    ];
    return (
      <Col sm={24} md={colSpan}>
        <Card bordered={false}>
          <div
            className="blue-card"
            style={{
              border:
                loopDetails &&
                loopDetails.current_team &&
                loopDetails.current_team.is_your_team
                  ? "2px solid #2a8ff7 "
                  : "none",
            }}
          >
            <Row>
              <Col span={12}>
                <h4 className="align-right"> Current Team Picking :</h4>
              </Col>
              <Col span={12}>
                <h4 className="mh-20">
                  {loopDetails &&
                  loopDetails.current_team &&
                  loopDetails.current_team.name
                    ? loopDetails.current_team.name
                    : "-"}
                  {loopDetails &&
                  loopDetails.current_team &&
                  loopDetails.current_team.is_your_team
                    ? " (YOU)"
                    : ""}
                </h4>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <h4 className="align-right"> Current Round :</h4>
              </Col>
              <Col span={12}>
                <h4 className="mh-20">
                  {" "}
                  {loopDetails && loopDetails.current_round
                    ? loopDetails.current_round
                    : "-"}
                </h4>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <h4 className="align-right"> Overall Draft Pick Number :</h4>
              </Col>
              <Col span={12}>
                <h4 className="mh-20">
                  {loopDetails && loopDetails.pick_no
                    ? loopDetails.pick_no
                    : "-"}
                </h4>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <h4 className="align-right"> Last Pick :</h4>
              </Col>
              <Col span={12}>
                <h4 className="mh-20">
                  {loopDetails &&
                    loopDetails.last_player &&
                    loopDetails.last_player.keyValue}{" "}
                  {loopDetails &&
                    loopDetails.last_team &&
                    loopDetails.last_team.name}{" "}
                </h4>
              </Col>
            </Row>
          </div>
          <br />

          <Row justify="space-between" align="bottom">
            <Col>
              <h4>Select Team</h4>
              <Select
                className="mr-20 mb-10"
                showSearch
                style={{ width: 150 }}
                placeholder="Select Team"
                optionFilterProp="children"
                // value= { defaultName && defaultName.name}
                // defaultValue =  { defaultName && defaultName}
                // defaultActiveFirstOption = {false}
                value={
                  selectTeam &&
                  selectTeam[0] &&
                  selectTeam[0].name &&
                  selectTeam[0].name
                }
                onChange={(value) => this.onTeamSelect(value)}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {teamOptions}
              </Select>
              <Button
                type="primary"
                shape="round"
                onClick={() => this.setState({ allDraftedPlayersModal: true })}
              >
                Draft Report
              </Button>
            </Col>
            <Col>
              <Radio.Group
                className="mt-10"
                onChange={this.onSorting}
                defaultValue="round"
              >
                <Radio.Button value="round">By Round</Radio.Button>
                <Radio.Button value="pos">By Position</Radio.Button>
              </Radio.Group>
            </Col>
          </Row>
          <br />
          <Row justify="space-between">
            <h3>Drafted Players</h3>
          </Row>
          <Table
            size={"small"}
            rowKey="player_id"
            // scroll={{ x: 500, y: 450 }}
            pagination={false}
            dataSource={tableData}
            loading={loadingDraftPlayer}
            columns={columns}
            // onChange={this.handleChange}
          />
        </Card>
      </Col>
    );
  };

  render() {
    const {
      loadingPickandSimulate,
      league,
      user,
      keeperModal,
      allDraftedPlayersModal,
    } = this.state;
    return (
      <Layout title="Draft" currentLeague={league}>
        {keeperModal && league && (
          <KeeperModal
            {...this.props}
            user={user}
            league={league}
            onCancel={() => this.setState({ keeperModal: false })}
            triggerApiCall={this.getAllData}
          />
        )}

        {allDraftedPlayersModal && league && (
          <AllDraftedPlayersModal
            user={user}
            league={league}
            onCancel={() => this.setState({ allDraftedPlayersModal: false })}
            triggerApiCall={this.getAllData}
          />
        )}

        {this.state.loading ||
        loadingPickandSimulate ||
        this.state.loadingRecommend ||
        this.state.loadingAdvanceAnalytics ? (
          <Spinner />
        ) : (
          <>
            {this.renderTopView()}

            {loadingPickandSimulate ? (
              <Spinner />
            ) : (
              <Row gutter={16}>
                {this.renderLeftView()}
                {this.renderRightView()}
              </Row>
            )}
            <Modal
              title="All Team players are already drafted."
              visible={this.state.visible}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              okText="Yes"
              cancelText="No"
            >
              <p>Do you want to clear all the drafted players?</p>
            </Modal>
            <Modal
              title="Reset Keeper"
              visible={this.state.resetKeeperModal}
              onOk={this.resetKeeper}
              onCancel={this.resetDraft}
              okText="Yes"
              cancelText="No"
            >
              <p>Do you want to delete all the keepers?</p>
            </Modal>
            <Modal
              title="Reset Draft"
              visible={this.state.resetDraftModal}
              onOk={() => {
                this.setState({ showRecommended: false });
                this.showCloseKeeperModal("OK");
              }}
              onCancel={() => this.showCloseKeeperModal("Cancel")}
              okText="Yes"
              cancelText="No"
            >
              <p>Do you want to reset the draft?</p>
            </Modal>
            <Modal
              title="Simulate to End"
              visible={this.state.showConfirmSimulateModal}
              onOk={() => {
                this.setState({ showRecommended: false });
                this.simulateToEndFuc(league, user, this.state.loopDetails);
                this.setState({ showConfirmSimulateModal: false });
              }}
              onCancel={() =>
                this.setState({ showConfirmSimulateModal: false })
              }
              okText="Yes"
              cancelText="No"
            >
              <p>Are you sure you want to simulate to end?</p>
            </Modal>
          </>
        )}
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    leaguePlayers: state.Player.leaguePlayers,
    teamsByLeague: state.Team.teamsByLeague,
    playerByTeam: state.Team.playerByTeam,
    undoLastPick: state.Team.undoLastPick,
    makeRecommedation: state.Team.makeRecommedation,
    getAllCurrentLoopDetails: state.Team.getAllCurrentLoopDetails,
    selectedDraftPlayer: state.Team.selectedDraftPlayer,
    simulateToEnd: state.Team.simulateToEnd,
    deleteAllDraftedPlayers: state.Team.deleteAllDraftedPlayers,
    mockToYourNextPick: state.Team.mockToYourNextPick,
    advancedAnalytics: state.Team.advancedAnalytics,
    keeperListData: state.Keeper.keeperByLeague,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getLeagueAvailablePlayers,
      getTeamsByLeague,
      getPlayerByTeam,
      getUndoLastPick,
      getmakeRecommedation,
      getCurrentLoopDetails,
      getselectDraftPlayer,
      getsimulateToEnd,
      deleteAllDraftedPlayes,
      getmockToYourNextPick,
      getadvancedAnalytics,
      getKeepersByLeague,
      deleteAllDraftedPlayesWithKeepers,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Draft);
