// This method is used to restrict dot(.), plus(+),minus(-) and e
export const onlyNumbers = (event) => {
  return [69, 187, 189, 190].includes(event.keyCode) && event.preventDefault();
};

export const sortByPosition = (array) => {
  let QB = [];
  array.forEach((data) => {
    
      QB.push(data);
   
  });
  QB = QB.sort((a, b) => (a.position - b.position));
  
  let result = [...QB];
  return result;
};
export const sortByRound = (array) => {
  let QB = [];
  array.forEach((data) => {
    
      QB.push(data);
   
  });
  QB = QB.sort((a, b) => (a.round_id - b.round_id));
  
  let result = [...QB];
  return result;
};
