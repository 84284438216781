import URL from "../../config/routes";
import types from "./action-types";
import axiosCall from "./index";

export const createKeeperList = (data) => {
  const path = URL.CREATE_KEEPER_LIST;
  const responseType = types.CREATE_KEEPER_LIST;
  return axiosCall("post", path, responseType, data);
};

export const getKeepersByLeague = (data) => {
  const path = URL.KEEPERS_BY_LEAGUE;
  const responseType = types.KEEPERS_BY_LEAGUE;
  return axiosCall("post", path, responseType, data);
};

export const checkForDraftStarts = (data) => {
  const path = URL.CHECK_FOR_DRAFT_STARTS;
  const responseType = types.CHECK_FOR_DRAFT_STARTS;
  return axiosCall("post", path, responseType, data);
};

export const updateKeeper = (data) => {
  const path = URL.UPDATE_KEEPER;
  const responseType = types.UPDATE_KEEPER;
  return axiosCall("put", path, responseType, data);
};

export const clearState = () => {
  return async (dispatch) => {
    dispatch({ type: types.CLEAR_KEEPER_STATE, updatePayload: {} });
  }
}
