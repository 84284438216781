export default {
  // Auth based actions
  LOGIN: "LOGIN",

  // Leagues based actions
  GET_ALL_LEAGUES: "GET_ALL_LEAGUES",
  CREATE_LEAGUE_WITH_TEAMS: "CREATE_LEAGUE_WITH_TEAMS",
  UPDATE_LEAGUE_WITH_TEAMS: "UPDATE_LEAGUE_WITH_TEAMS",
  DELETE_LEAGUE: "DELETE_LEAGUE",

  // Scoring based actions
  CREATE_SCORING: "CREATE_SCORING",
  GET_ALL_SCORING_SYSTEM: "GET_ALL_SCORING_SYSTEM",

  // Player based actions
  GET_LEAGUE_AVALIABLE_PLAYERS: "GET_LEAGUE_AVALIABLE_PLAYERS",
  GET_ALL_AVALIABLE_PLAYERS: "GET_ALL_AVALIABLE_PLAYERS",
  GET_ALL_DRAFTED_PLAYERS: "GET_ALL_DRAFTED_PLAYERS",

  // Team based actions
  GET_ALL_TEAMS_BY_LEAGUE_ID: "GET_ALL_TEAMS_BY_LEAGUE_ID",
  GET_ALL_PLAYER_BY_TEAM_ID: "GET_ALL_PLAYER_BY_TEAM_ID",
  GET_ALL_UNDO_LAST_PICK: "GET_ALL_UNDO_LAST_PICK",
  GET_ALL_MAKE_RECOMMEDATION: "GET_ALL_MAKE_RECOMMEDATION",
  GET_CURRENT_LOOP_DETAILS: "GET_CURRENT_LOOP_DETAILS",
  GET_SELECT_DRAFT_PLAYES: "GET_SELECT_DRAFT_PLAYES",
  GET_SIMULATE_TO_END: "GET_SIMULATE_TO_END",
  DELETE_ALL_DRAFTED_PLAYES: "DELETE_ALL_DRAFTED_PLAYES",
  GET_MOCK_TO_YOUR_NEXT_PICK: "GET_MOCK_TO_YOUR_NEXT_PICK",
  GET_ADVANCED_ANALYTICS: "GET_ADVANCED_ANALYTICS",

  // Keeper based actions
  CREATE_KEEPER_LIST: "CREATE_KEEPER_LIST",
  KEEPERS_BY_LEAGUE: "KEEPERS_BY_LEAGUE",
  CHECK_FOR_DRAFT_STARTS: "CHECK_FOR_DRAFT_STARTS",
  UPDATE_KEEPER: "UPDATE_KEEPER",
  CLEAR_KEEPER_STATE: "CLEAR_KEEPER_STATE",

  //Algorithm based actions
  SNAKE_DRAFT_LOOP: "SNAKE_DRAFT_LOOP",
  THIRD_ROUND_REVERSAL_DRAFT_LOOP: "THIRD_ROUND_REVERSAL_DRAFT_LOOP",
};
