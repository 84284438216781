import React, { Component } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import Login from "./components/login";
import LeagueList from "./components/leagues/list";
import Draft from "./components/draft/draft";
import SetupDraft from "./components/draft/setupDraft";
import AllTeams from "./components/teams/teams";
import { isLoggedIn, setAuthToken } from "./services/auth";

import { AuthContext, AuthProvider } from "./firebase/Auth";

const history = createBrowserHistory();

const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
  const { currentUser } = React.useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={(routeProps) =>
        !!currentUser ? (
          <RouteComponent {...routeProps} />
        ) : (
          // <Redirect to={"/login"} />
          <></>
        )
      }
    />
  );
};

export default class Routes extends Component {
  async UNSAFE_componentWillMount() {
    const user = await isLoggedIn();
    if (user) {
      setAuthToken();
      this.setState({ user });
    } else {
      history.push("/login");
    }
  }

  render() {
    return (
      <Router history={history}>
        <Switch>
          <AuthProvider>
            <PrivateRoute exact path="/" component={LeagueList} />
            <Route path="/login" component={Login} />
            <PrivateRoute path="/draft" component={Draft} />
            <PrivateRoute path="/setup-draft" component={SetupDraft} />
            <PrivateRoute path="/all-teams" component={AllTeams} />
          </AuthProvider>
        </Switch>
      </Router>
    );
  }
}
