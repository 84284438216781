import { combineReducers } from "redux";
import Auth from "./auth.reducer";
import Leagues from "./leagues.reducer";
import Score from "./scoring.reducer";
import Player from "./player.reducer";
import Team from "./teams.reducer";
import Keeper from "./keeper.reducer";

const rootReducer = combineReducers({
  Auth,
  Leagues,
  Score,
  Player,
  Team,
  Keeper,
});

export default rootReducer;
