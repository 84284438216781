import React, { useEffect, useState } from "react";
import { createBrowserHistory } from "history";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";
import app from "./app";

const history = createBrowserHistory();

const getUserRoles = firebase.functions().httpsCallable("getUserRoles");

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    app.auth().onAuthStateChanged((user) => {
      setCurrentUser(user);
      getUserRoles().then((rolesData) => {
        const permitLogin = rolesData.data.some(
          (i) => i === "role_AhGMmvpQVrdmxIPqodij" || i === "role_OuhicOWKJHHvqVvkOGXA" || i === "role_I54ZyLBYFigJqJS59MuN"
        );
        if (!permitLogin) {
          history.push("/login");
        }
      });
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
