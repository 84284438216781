import types from "../actions/action-types";

export default (state = [], action) => {
  switch (action.type) {
    case types.GET_ALL_TEAMS_BY_LEAGUE_ID:
    case `${types.GET_ALL_TEAMS_BY_LEAGUE_ID}_REJECTED`:
      return {
        ...state,
        teamsByLeague: action.updatePayload,
      };
      case types.GET_ALL_PLAYER_BY_TEAM_ID:
        case `${types.GET_ALL_PLAYER_BY_TEAM_ID}_REJECTED`:
          return {
            ...state,
            playerByTeam: action.updatePayload,
          };

          case types.GET_ALL_UNDO_LAST_PICK:
        case `${types.GET_ALL_UNDO_LAST_PICK}_REJECTED`:
          return {
            ...state,
            undoLastPick: action.updatePayload,
          };

          case types.GET_ALL_MAKE_RECOMMEDATION:
            case `${types.GET_ALL_MAKE_RECOMMEDATION}_REJECTED`:
              return {
                ...state,
                makeRecommedation: action.updatePayload,
              };

              case types.GET_CURRENT_LOOP_DETAILS:
            case `${types.GET_CURRENT_LOOP_DETAILS}_REJECTED`:
              return {
                ...state,
                getAllCurrentLoopDetails: action.updatePayload,
              };
              
              case types.GET_SELECT_DRAFT_PLAYES:
                case `${types.GET_SELECT_DRAFT_PLAYES}_REJECTED`:
                  return {
                    ...state,
                    selectedDraftPlayer: action.updatePayload,
                  };

                  case types.GET_SIMULATE_TO_END:
                    case `${types.GET_SIMULATE_TO_END}_REJECTED`:
                      return {
                        ...state,
                        simulateToEnd: action.updatePayload,
                      };

                  case types.DELETE_ALL_DRAFTED_PLAYES:
                    case `${types.DELETE_ALL_DRAFTED_PLAYES}_REJECTED`:
                      return {
                        ...state,
                        deleteAllDraftedPlayers: action.updatePayload,
                      };
                  
                      case types.GET_MOCK_TO_YOUR_NEXT_PICK:
                        case `${types.GET_MOCK_TO_YOUR_NEXT_PICK}_REJECTED`:
                          return {
                            ...state,
                            mockToYourNextPick: action.updatePayload,
                          };

                          case types.GET_ADVANCED_ANALYTICS:
                            case `${types.GET_ADVANCED_ANALYTICS}_REJECTED`:
                              return {
                                ...state,
                                advancedAnalytics: action.updatePayload,
                              };
                          
                      
    default:
      return state;
  }
};
