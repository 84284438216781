import types from "../actions/action-types";

export default (state = [], action) => {
  switch (action.type) {
    case types.CREATE_KEEPER_LIST:
    case `${types.CREATE_KEEPER_LIST}_REJECTED`:
      return {
        ...state,
        createKeeperResponse: action.updatePayload,
      };
    case `${types.KEEPERS_BY_LEAGUE}_LOADING`:
      return {
        ...state,
        keeperByLeagueLoading: true,
      };
    case types.KEEPERS_BY_LEAGUE:
    case `${types.KEEPERS_BY_LEAGUE}_REJECTED`:
      return {
        ...state,
        keeperByLeagueLoading: false,
        keeperByLeague: action.updatePayload,
      };
    case types.UPDATE_KEEPER:
    case `${types.UPDATE_KEEPER}_REJECTED`:
      return {
        ...state,
        updateKeeperResponse: action.updatePayload,
      };
    case types.CLEAR_KEEPER_STATE:
      return {
        ...state,
        updateKeeperResponse: action.updatePayload,
        createKeeperResponse: action.updatePayload,
      };
    case types.CHECK_FOR_DRAFT_STARTS:
      return {
        ...state,
        checkForDraft: action.updatePayload,
      };

    default:
      return state;
  }
};
