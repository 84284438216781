import { DeleteOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row, Table } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "../../assets/scss/login.scss";
import {
  deleteLeague,
  getAllLeagues,
} from "../../redux/actions/leagues.action";
import { isLoggedIn } from "../../services/auth";
import Layout from "../common/layout";

class LeagueList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNo: 1,
      allLeagues: [],
      loading: true,
      currentSelectedId: null,
      showdeleteModal: false,
    };
  }

  async componentDidMount() {
    await this.getAllLeagues();
  }

  async componentDidUpdate(prevProps) {
    const { deleteLeagueState } = this.props;
    if (
      JSON.stringify(prevProps.deleteLeagueState) !==
      JSON.stringify(deleteLeagueState)
    ) {
      if (deleteLeagueState?.status === "success") {
        this.getAllLeagues();
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { leaguesList } = nextProps;
    if (leaguesList?.status === "success") {
      this.setState({ allLeagues: leaguesList.leagues, loading: false });
    }
  }

  getAllLeagues = async () => {
    const { pageNo } = this.state;
    const { getAllLeagues, history } = this.props;
    const user = await isLoggedIn();
    if (user) {
      const body = {
        uid: user.uid,
        pagination: {
          pageNo,
          limit: 300,
        },
      };
      getAllLeagues(body);
    } else {
      history.push("/login");
    }
  };

  deleteleakage = (value) => {
    const { deleteLeague } = this.props;
    this.setState({ showdeleteModal: false });
    if (value) {
      deleteLeague({ leagueId: this.state.currentSelectedId });
    } else {
      this.setState({ currentSelectedId: null });
    }
  };

  showDeleteModal = (id) => {
    this.setState({ currentSelectedId: id, showdeleteModal: true });
  };

  render() {
    const { history } = this.props;
    const { loading, allLeagues } = this.state;
    const columns = [
      {
        title: "League Details",
        dataIndex: "name",
        sorter: (a, b) => a.name.localeCompare(b.name),
      },
      {
        title: "No of Teams",
        dataIndex: "no_of_teams",
        sorter: (a, b) => a.no_of_teams - b.no_of_teams,
        width: 170,
      },
      {
        title: "Draft Position",
        dataIndex: "user_draft_pos",
        sorter: (a, b) => a.user_draft_pos - b.user_draft_pos,
        width: 170,
      },
      {
        title: "",
        dataIndex: "user_draft_pos",
        width: 130,
        align: "center",
        render: (text, record) => {
          return (
            <Row gutter={16} justify="center">
              <Col span={12}>
                <Button
                  className="mr-10"
                  type="default"
                  shape="round"
                  size="small"
                  onClick={() =>
                    history.push("setup-draft", { league: record, edit: true })
                  }
                >
                  View
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  icon={<DeleteOutlined />}
                  type="link"
                  shape="round"
                  size="small"
                  danger
                  onClick={() => this.showDeleteModal(record.league_id)}
                />
              </Col>
            </Row>
          );
        },
      },
    ];

    return (
      <Layout title="Leagues">
        <Modal
          title="Alert"
          visible={this.state.showdeleteModal}
          onOk={() => this.deleteleakage(true)}
          onCancel={() => this.deleteleakage(false)}
          okText="Delete"
        >
          <p>Are you sure to delete the league?</p>
        </Modal>
        <Row justify="center">
          <Col sm={24} md={22}>
            <Row justify="end" className="mb-10">
              <Button
                onClick={() => history.push("setup-draft")}
                className="mh-20"
                type="primary"
                shape="round"
              >
                Create League
              </Button>
            </Row>
            <Table
              size="small"
              loading={loading}
              rowKey={(record) => record.league_id}
              dataSource={allLeagues}
              columns={columns}
              pagination={false}
              // scroll={{ x: true }}
            />
          </Col>
        </Row>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    leaguesList: state.Leagues.leaguesList,
    deleteLeagueState: state.Leagues.deleteLeague,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getAllLeagues,
      deleteLeague,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(LeagueList);
